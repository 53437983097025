import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-brand-portfolio',
  templateUrl: './brand-portfolio.component.html',
  styleUrls: ['./brand-portfolio.component.css']
})
export class BrandPortfolioComponent {
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsPortfoliocollection = this.firestore.collection('BrandPortfolio').valueChanges({ idField: 'id' }) as Observable<[]>;
  brandsPortfolioDetailscollection = this.firestore.collection('BrandPortfolioDetails').valueChanges({ idField: 'id' }) as Observable<[]>;



  logInFlag: boolean;
  loading: boolean;
  brandList: any = [];
  portFolioName: any = ""
  brandPortfolioList: any = [];
  brandPortfolioDetailsList: any = [];
  selectedPortfolioId = '';
  portfolioLink: any;




  constructor(public commonService: CommonService, private firestore: AngularFirestore, private router: Router) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()


    this.brands.subscribe((brand) => {
      this.brandList = brand
    })

 
    

    this.brandsPortfoliocollection.subscribe((portfolio) => {
      this.brandPortfolioList = portfolio
      console.log(this.brandPortfolioList)
    })


    this.brandsPortfolioDetailscollection.subscribe((portfolio) => {
      this.brandPortfolioDetailsList = portfolio
      console.log(this.brandPortfolioDetailsList)
    })

  }


  ngOnInit(): void {
    let title = 'Yologram - brand-portfolio';
    this.commonService.changeTitle(title);
    this.checkPageAuthorization()
    if(!this.logInFlag){
      this.router.navigateByUrl("/drip");
    }
  }


  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');

    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  onSelectPortfolio
    (data) {
this.updateList () 
  }

  updateList () { 
    this.brandList.sort((a, b) => {
      // Convert the condition to a numeric value for sorting: true (has a role ID) comes before false (no role ID)
      let aHasRoleId = this.getBrandRoleId(a.id) != undefined ? 1 : 0;
      let bHasRoleId = this.getBrandRoleId(b.id) != undefined ? 1 : 0;

      // We want brands with a role ID (true) to come first, so we sort in descending order of the boolean condition
      return bHasRoleId - aHasRoleId;
  });
   }

  copyPortfolio() {
    let setportfolio = this.selectedPortfolioId;
    let portfolioname = this.brandPortfolioList.find((p) => p.BrandPortfolioId
      == setportfolio)
    console.log(setportfolio)
    console.log(portfolioname)
    this.router.navigate(['/threads', { 'Gp': portfolioname.BrandPortfolioName }])
    
  }





  
  addportfolios() {
    if (this.portFolioName == '') {
      alert('Please enter Brand portfolio name.');
      return;
    } else {
      const portFolioNameLowerCase = this.portFolioName.toLowerCase(); // Convert input to lowercase
      // Check if the portfolio name already exists
      this.firestore.collection('BrandPortfolio')
        .get()
        .subscribe((querySnapshot: any) => { // Explicitly specify the type or cast to 'any'
          const existingPortfolios = querySnapshot.docs.map((doc: any) => doc.data().BrandPortfolioName.toLowerCase()); // Explicitly specify the type or cast to 'any'
          if (existingPortfolios.includes(portFolioNameLowerCase)) {
            alert('Brand portfolio name already exits. Name must be unique.');
            return;
          } else {
            let pageObject = {
              BrandPortfolioName: this.portFolioName
            };
  
            // Add the portfolio
            this.firestore.collection('BrandPortfolio').add(pageObject)
              .then(docRef => {
                this.firestore.collection('BrandPortfolio').doc(docRef.id).update({
                  BrandPortfolioId: docRef.id
                }).then(() => {
                  this.portFolioName = '';
                  alert("Brand Portfolio Added Successfully.");
                }).catch(updateError => {
                  console.error("Error updating document with ID:", updateError);
                  alert("An error occurred while updating the Brand Portfolio ID.");
                });
              }).catch(addError => {
                console.error("Error adding Brand Portfolio:", addError);
                alert("An error occurred while adding the Brand Portfolio.");
              });
          }
        });
    }
  }
  
  
  deletePortfolio() {

    let selectbrand = this.brandPortfolioDetailsList.find((brand) => brand.BrandPortfolioId == this.selectedPortfolioId)
    console.log("selectedBrandportfolio", selectbrand)

    if (selectbrand || selectbrand !== undefined) {
      alert('Cannot delete this portfolio, as it has brand assigned');
      return;
    }


    if (!this.selectedPortfolioId || this.selectedPortfolioId === '') {
      alert('Please select a BrandPortfolio to delete.');
      return;
    }

    // Assuming firestore is correctly injected and available
    this.firestore.collection('BrandPortfolio').doc(this.selectedPortfolioId).delete().then(() => {
      // On successful deletion, remove the item from the local list and reset the selected ID
      this.brandPortfolioList = this.brandPortfolioList.filter(portfolio => portfolio.BrandPortfolioId !== this.selectedPortfolioId);
      this.selectedPortfolioId = '';
      alert('Brand Portfolio deleted successfully.');
    }).catch(error => {
      console.error('Error deleting BrandPortfolio:', error);
      alert('Failed to delete BrandPortfolio.');
    });
  }



  updateBrandStatus($event, selectedBrandportfolio, brand) {
    this.loading = true
    if (!this.selectedPortfolioId) {
      this.selectedPortfolioId = undefined
      alert("Please Select Group..")
      return
    }
    console.log("selectedBrandportfolio", selectedBrandportfolio)
    let brandId = brand.id
    let portfolio = selectedBrandportfolio

    if (portfolio != undefined) {
      this.firestore.collection('BrandPortfolioDetails').doc(portfolio.id).delete().then(() => {
        this.loading = false
        this.updateList() 
      });
    } else {
      let branportfolioObject = {
        BrandPortfolioId: this.selectedPortfolioId,
        BrandId: brandId
      }
      this.firestore.collection('BrandPortfolioDetails').add(branportfolioObject).then(() => {
        this.loading = false
        this.updateList() 
      });

    }
  }


  getBrandRoleId(id) {
    let selectedBrand = id
    let selectedportfolio = this.selectedPortfolioId
    let selectbrand = this.brandPortfolioDetailsList.find((brand) => brand.BrandId == selectedBrand && brand.BrandPortfolioId == selectedportfolio)
    return selectbrand
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/brand-portfolio' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }

  preventSpacesAndDots(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === '.') {
        // Prevent the default action to stop characters from being entered
        event.preventDefault();
        // Show an alert to the user
        alert('Space or dot not allowed');
        
    }
}

onPaste(event: ClipboardEvent) {
  // Get the pasted text
  const pastedText = event.clipboardData.getData('text');
  // Check if the pasted text contains spaces or dots
  if (pastedText.includes(' ') || pastedText.includes('.')) {
      // Prevent the default paste action
      event.preventDefault();
      // Show an alert to the user
    
      alert('Pasting with space or dot is not allowed')
  }
}

copyToClipboard() {
  let setportfolio = this.selectedPortfolioId;
    let portfolioname = this.brandPortfolioList.find((p) => p.BrandPortfolioId
      == setportfolio)
    console.log(setportfolio)
    console.log(portfolioname)
    // this.router.navigate(['/threads', { 'Gp': portfolioname.BrandPortfolioName }])
  let link = 'https://yolomove-social-live.web.app/threads;Gp='+portfolioname.BrandPortfolioName 

  navigator.clipboard.writeText(link).then(() => {
    console.log('Text copied to clipboard');
    alert('link copied to clipboard')
    // You can also show some message to the user acknowledging that the text was copied.
  }).catch(err => {
    console.error('Could not copy text: ', err);
    // You might want to fallback to a different method or notify the user of the failure.
  });
}

}
