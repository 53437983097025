<div class="carousel" #garmentDummySCroll>
  <div class="item" *ngFor="let data of scrollingImages">
    <img class="imageBorder-class" *ngIf="!loading  && (data.fileurl != undefined || data.fileurlMini != undefined) && data.image == undefined"
      (click)="open2dPoup3(data)" style="height: 75px;
              width: 75px !important;object-fit: cover;"
            [appLazyLoad]="data.fileurlMini" defaultImage="{{ data.fileurlMini }}">
    <img class="imageBorder-class" *ngIf="!loading  && data.fileurl == undefined && data.image != undefined"
      (click)="open2dPoupDummy(data)" style="height: 75px; 
              width: 75px !important;object-fit: cover;" 
            [appLazyLoad]="data.image"  defaultImage="{{ data?.image }}">
  </div>
</div>


