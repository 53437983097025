<div (click)="closePanel()" >
    <app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>
    <div (scroll)="scrollHandler()" #userFeeds class="roksan"  [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}">
        <div class="col-lg-12  page-scroll sticky-band" *ngIf="pageScroll > 30">
            <span class="d-flex justify-content-center ">
                <div class=" col-lg-12 band justify-content-center">
                    <div class="static-band " style="margin-left: 0px  !important;">
                        <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                            <img *ngIf="userProfileData?.ProfilePicture != undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                            <img *ngIf="userProfileData?.ProfilePicture == undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                        </div>
                        <div class="w-100 col-12 d-flex">
                            <div class="col-9 text-start">
                                <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                    (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                                <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black user-name"
                                    (click)="getSnapShotListByUserID()">
                                    {{userProfileData?.DisplayName}}
                                </h5>
                            </div>
                            <div class="col-3 "><button type="button" style="font-size: 10px;"
                                    class="btn btn-outline-info">Follow</button>
                            </div>
                        </div>
                        <!-- <div class="text-start" style="height: 25px; width: 100%;">
                            <h6    *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">
                            🧍🏽{{userAvatarData.AvatarName}}
                        </h6>

                            <h6 *ngIf="userAvatarData.AvatarName" class="avatr  text-start ">
                                <img style="width: 15px; height: 15px;" src="./assets/body.png"/>  {{userAvatarData.AvatarName}}
                            </h6>
                           
                        </div> -->
                        <div class="d-flex align-items-center avatar-sticky" *ngIf="newArray.length != 0">
                            <img class="avatar-image" style="width: 40px; height: 40px;"
                                [src]="userAvatarData.AvatarPreviewPortraitUrl">
                            <span class="avatar-name"><span
                                    class="avatar-details">{{userAvatarData.AvatarName}}</span></span>
                        </div>
                        <div>
                            <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                <div class="brandbtn">
                                    <h6 class="m-1" (click)="selectSummeryView('Story')"
                                        [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>

                                            <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                src="  ./assets/storystyle/story-white.png">
                                            <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                src=" ./assets/storystyle/storyblack.png">


                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Brands')">
                                    <h6 class="m-1"
                                        [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                src="./assets/brand_white.png">
                                            <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                src="./assets/brand_black.png">

                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Style')">
                                    <h6 class="m-1 "
                                        [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_white.png">
                                            <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_black.png">
                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div *ngIf="filterSnap" class="justify-content-center">
            <div class="col-lg-12 band">
                <div *ngIf="snaploader2D" class="text-center">
                    <div class="spinner-border m-2 p-2" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="static-band justify-content-center"
                    *ngIf=" pageScroll == undefined ||  pageScroll < 30    ">
                    <div class="d-flex m-1 profile-popup text-white " data-bs-dismiss="modal">
                        <img *ngIf="userProfileData?.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.ProfilePicture}}>
                        <img *ngIf="userProfileData?.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.photoURL}}>
                    </div>
                    <div class="w-100 col-12 d-flex">
                        <div class="col-9 text-start">
                            <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                            <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black user-name"
                                (click)="getSnapShotListByUserID()">
                                {{userProfileData.DisplayName}}
                            </h5>
                        </div>
                        <div class="col-3 "><button type="button" style="font-size: 10px;"
                                class="btn btn-outline-info">Follow</button>
                        </div>
                    </div>
                    <div class="text-start" style="height: 25px; width: 100%; margin-bottom: 18px;
                    margin-left: 20px;">

                        <!-- <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">
                            🧍🏽{{userAvatarData.AvatarName}}
                        </h6> -->


                        <!-- <h6 *ngIf="userAvatarData.AvatarName" class="avatr  text-start ">
                            🧍🏽{{userAvatarData.AvatarName}}
                        </h6> -->


                        <div class="d-flex align-items-center " *ngIf="newArray.length != 0">
                            <img class="avatar-image" style="width: 40px; height: 40px;"
                                [src]="userAvatarData.AvatarPreviewPortraitUrl">
                            <span class="avatar-name"><span
                                    class="avatar-details">{{userAvatarData.AvatarName}}</span></span>
                        </div>



                    </div>


                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                        <div class="brandbtn">
                            <h6 class="m-1   " (click)="selectSummeryView('Story')"
                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                        src="  ./assets/storystyle/story-white.png">
                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                        src=" ./assets/storystyle/storyblack.png">

                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                        </div>
                        <div class="brandbtn" (click)="selectTab('Brands')">
                            <h6 class="m-1 "
                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                        src="./assets/brand_white.png">
                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                        src="./assets/brand_black.png">


                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                        </div>
                        <div class="brandbtn">
                            <h6 class="m-1  " (click)="selectTab('Style')"
                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_white.png">
                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_black.png">
                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class ">Style</h6>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center story-center d-flex">
                    <h6 class="m-2 text-primary  color-class">
                        <!-- <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                        <div *ngIf="userFiledetails.length == 0 "
                            class="text-center col-12 justify-content-center twoimages">
                            <app-slider-two-d [images]="images"></app-slider-two-d>
                        </div>
                        <div *ngIf="userFiledetails.length != 0 "
                            class="text-center col-12 justify-content-center twoimages">
                            <app-slider-two-d [images]="newArray" [UserId]="userId"></app-slider-two-d>
                        </div>
                    </div> -->
                        <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                            <div *ngIf="newArray.length != 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="newArray" [UserId]="userId"></app-slider-two-d>
                            </div>
                        </div>
                    </h6>
                </div>

                <div style="height: 300px;width:260px; background-color: black;">
                    <app-news3-d-templete [GarmentsGender]="genders" [EnvUrl]="defaultEnvironment"
                        [upperwearUrl]="UpperWearfinalUrl" [FunctionFlag]="functionflagValue"
                        [bottomwearUrl]="garmentbottom" [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear"
                        [footwearUrl]="footwear" (calledVlueIntilized)="calledVlueIntilized($event)">
                    </app-news3-d-templete>
                </div>


                <!-- <div style="margin-top: 100px !important;" class="col-md-4  col-sm-4 mt-sm-4"
                *ngIf="userAvatarData?.AvatarPreviewFullBodyUrl">
                <div style="height: 300px; width:260px;">
                    <img [src]="userAvatarData?.AvatarPreviewFullBodyUrl" class="img-fluid" alt="Full Body Preview">
                </div>
            </div>
            <div class="col-md-4  col-sm-4">
                <div *ngIf="userAvatarData?.AvatarPreviewPortraitUrl">
                    <div style="height: 300px; width:260px;">
                        <img [src]="userAvatarData?.AvatarPreviewPortraitUrl" class="img-fluid" alt="Portrait Preview">
                    </div>
                </div>
            </div> -->


                <div class="d-block text-white text-center justify-content-center">
                    <h6>Avatar : {{userAvatarData.AvatarName}} ({{userAvatarData.Gender =='MALE' ? 'M': 'F'}})</h6>
                    <h6>Size : {{userAvatarData.FatBlendshapeValue}}</h6>
                    <h6>Height Scale : {{userAvatarData.HeightScale}}</h6>

                    <!-- <h6>cBottomwearID : {{userAvatarData.cBottomwearID}}</h6>
                    <h6>cFootwearID : {{userAvatarData.cFootwearID}}</h6>
                    <h6>cFullBodyID : {{userAvatarData.cFullBodyID}}</h6>
                    <h6>cHeadgearID : {{userAvatarData.cHeadgearID}}</h6>
                    <h6>cHeeledFootwearID : {{userAvatarData.cHeeledFootwearID}}</h6>
                    <h6>cUpperwearID : {{userAvatarData.cUpperwearID}}</h6> -->



                </div>
                <div class="large-box">
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 m-1">
                            <img class="m-1" style="width:45px; height:45px !important; color:black;"
                                (click)="openBrandPanel(userAvatarData.cHeadgearID,$event)"
                                *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cHeadgearID)" />
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            (click)="openGarmentNav(userAvatarData,'HeadwearID',userAvatarData.cHeadgearID,'headgear',$event)"
                            *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID!= ''">
                            <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cHeadgearID) == '0' "
                                style="width:15px !important; color:black ; opacity: 0.4;"
                                src="./assets/newsfeed/headgear-new.png">
                            <span class="caption-values"
                                *ngIf="userAvatarData.cHeadgearID != undefined && userAvatarData.cHeadgearID != ''">
                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                    title="Cloth Details">
                                    {{getGarmentName(userAvatarData.cHeadgearID).substring(0,25)}}
                                </span>&nbsp;
                            </span>
                            <!-- <span
                                        *ngIf="!showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                        <a class="pintrest-icon"
                                            *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                                            (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                        </a>
                                    </span>
                                    <a (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                        class="pintrest-icon"
                                        *ngIf="showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                        <img style="width:20px !important; color:black"
                                            class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                    </a> -->
                        </div>
                    </div>
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 m-1 ">
                            <img class="m-1" (click)="openBrandPanel(userAvatarData.cUpperwearID,$event)"
                                style="width:45px; height:45px !important; color:black ; "
                                *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cUpperwearID)" />
                            <img class="m-1" (click)="openBrandPanel(userAvatarData.cFullBodyID,$event)"
                                style="width:45px; height:45px !important; color:black ;"
                                *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cFullBodyID)" />
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cUpperwearID != undefined &&userAvatarData.cUpperwearID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'UpperwearID',userAvatarData.cUpperwearID,'upperwear',$event)">
                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '1' "
                                    style="width:15px !important; color:black; opacity: 0.4;"
                                    src="./assets/realtype/upperwear.png">
                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cUpperwearID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cUpperwearID != undefined && userAvatarData.cUpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details">
                                        {{getGarmentName(userAvatarData.cUpperwearID).substring(0,25)}}
                                    </span>&nbsp;
                                </span>
                            </span>
                            <!-- <span
                                *ngIf="!showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                <a class="pintrest-icon"
                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID)"
                                    (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black"
                                        class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" /></a>
                            </span>
                            <a (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                class="pintrest-icon"
                                *ngIf="showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                <img style="width:20px !important; color:black"
                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                            </a> -->
                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cFullBodyID != undefined &&userAvatarData.cFullBodyID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'UpperwearID',userAvatarData.cFullBodyID,'upperwear',$event)">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFullBodyID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cFullBodyID != undefined && userAvatarData.cFullBodyID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details">
                                        {{getGarmentName(userAvatarData.cFullBodyID).substring(0,25)}}
                                    </span>&nbsp;
                                </span>
                            </span>
                            <!-- <span
                            *ngIf="!showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID)"
                                (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black"
                                    class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" /></a>
                        </span>
                        <a (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                            class="pintrest-icon"
                            *ngIf="showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                            <img style="width:20px !important; color:black"
                                class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                        </a> -->
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 d-flex ">
                        <div class="col-2 col-sm-2  m-1">
                            <img (click)="openBrandPanel(userAvatarData.cBottomwearID,$event)"
                                style="width:45px; height:45px !important; color:black ; "
                                *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cBottomwearID)" />

                        </div>
                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cBottomwearID != undefined && userAvatarData.cBottomwearID != ''">
                            <span
                                (click)="openGarmentNav(userAvatarData,'BottomwearID',userAvatarData.cBottomwearID,'bottomwear',$event)">


                                <img class="gar-icon"
                                    *ngIf="getGarmentrealtype(userAvatarData.cBottomwearID) == '2'"
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="userAvatarData.cBottomwearID != undefined &&userAvatarData.cBottomwearID!= ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details">{{getGarmentName(userAvatarData.cBottomwearID).substring(0,20)}}
                                    </span>&nbsp;
                                </span>
                            </span>

                            <!-- <span
                                *ngIf="!showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                <a class="pintrest-icon"
                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                    (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black"
                                        class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                </a>
                            </span>
                            <a (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                class="pintrest-icon"
                                *ngIf="showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                <img style="width:20px !important; color:black"
                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                            </a> -->
                        </div>
                    </div>
                    <div class="col-12 col-sm-12  d-flex">
                        <div class="col-2 col-sm-2 ">
                            <img class="m-2" (click)="openBrandPanel(userAvatarData.cFootwearID,$event)"
                                style="width:45px; height:45px !important; color:black ;"
                                *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3'"
                                class="rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(userAvatarData.cFootwearID)" />
                        </div>

                        <div class="col-8 col-sm-8 clothName p-1"
                            *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                            <img class="gar-icon" *ngIf="getGarmentrealtype(userAvatarData.cFootwearID) == '3' "
                                (click)="openGarmentNav(userAvatarData,'FootwearID',userAvatarData.cFootwearID,'footwear',$event)"
                                style="width:15px !important; color:black ; opacity: 0.4;"
                                src="./assets/realtype/footwear.png">

                            <span class="caption-values"
                                (click)="openGarmentNav(userAvatarData,'FootwearID',userAvatarData.cFootwearID,'footwear',$event)"
                                *ngIf="userAvatarData.cFootwearID != undefined && userAvatarData.cFootwearID != ''">
                                <span class="garment-name" data-toggle="popover" data-placement="left"
                                    title="Cloth Details">
                                    {{getGarmentName(userAvatarData.cFootwearID).substring(0,25)}}
                                </span>&nbsp;
                            </span>


                            <!-- <span
                                *ngIf="!showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                <a class="pintrest-icon"
                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                    (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black"
                                        class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                </a>
                            </span>
                            <a (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                class="pintrest-icon"
                                *ngIf="showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                <img style="width:20px !important; color:black"
                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="snaploader" class="text-center">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab  == 'user-story'" class="story-slass">
            <!-- 
            <app-user-story [userSnapShotsInput]="filterSnap" [UserId]="userId" [AvatrId]="avatarID"
                        [StoryIds]="selectedStoryId" [PageCount]="pagecount" [pageNumber]="pageNumber"
                        (outPageNumber)="setPageNumber($event)"
                        [brandPageFlag]="brandPageFlag" [rootFlag]="rootFlag"
                        [routLogInFlag]="routLogInFlag">
            </app-user-story>
             -->

            <div class="drips-container">
                <app-drips (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
                    [mainMenuFlag]="mainMenuFlag" [showpanel]="showpanel" [userSnapShotsInput]="filterSnap"
                    [StoryIds]="selectedStoryId" [UserId]="userId" [AvatrId]="avatarID" [PageCount]="pagecount"
                    [pageNumber]="pageNumber" [nextPage]="nextPage">
                </app-drips>
            </div>
            
        </div>
        <div *ngIf="selectedTab  == 'story'" class="row justify-content-center mb-5  ">
            <div class="col-12 div-center">
                <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
                <div class="row w-100">
                    <div class="col-4 p-0" *ngFor="let snapshot of filterSnap; let i=index;">
                        <div *ngIf="snapshot.SnapshotFileUrl != ''"
                            class="d-flex align-items-center justify-content-center m-1">
                            <img class="w-100 shadow-1-strong rounded m-1" (click)="scrollToStory(snapshot)"
                                [src]="snapshot.SnapshotFileUrl">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="filterSnap.length >= 12">
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID == undefined }">
                    <h6 (click)="nextButtonForAvatar(counter)"><b>More</b></h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-close" id="closePannel" *ngIf="brandCollapseFlag" (click)=" clickOnPanel($event)">
    <div id="mySidenav" class="sidenav" *ngIf="closeDiv && !wholeDiv">
        <div *ngIf="brandCollapseFlag">
            <app-drips-brand-panel [newselectedBrandId]="selectedBrandId" [brandData]="brandData"
                [newbrandUrl]="newbrandUrl"></app-drips-brand-panel>
        </div>
    </div>
</div>
<div class="brand-close" id="closePannel" *ngIf="germentCollapseFlag" (click)=" clickOnPanel($event)">
    <div id="mySidenav" class="sidenav" *ngIf="closeDiv && !wholeDiv">
        <div *ngIf="germentCollapseFlag">
            <app-drips-garment-panel [garmentId]="selecteGarmentId" [typeOfGarment]="typeOfGarment"
                [selectedSnapShot]="selectedGarmentName" [brandUrl]="brandUrl" [RealType]="realType"
                [garmentData]="garmentData" [typeWear]="garmentTypeID">
            </app-drips-garment-panel>
        </div>
    </div>
</div>