
<div >
    <div *ngIf="this.brandUrl != undefined" class="text-center d-flex col-2 shadow-brand-slide">
        <img class="brandLogo " [src]="this.brandUrl" (click)="navigateOnThread()">
        <div class="text-end me-1 mt-1" style="pointer-events: auto;">
            <div>
                <img class="heart" (click)="clickOnHeart($event)" *ngIf="brandheartFlag"
                    style="width:18px !important; color:#000000 ;margin-left: 57px"
                    src="./assets/storystyle/newStoryIcon/heart.png">
    
                <img (click)="clickOnHeart($event)" *ngIf="!brandheartFlag" class="heart"
                    style="width:18px !important; color:black;margin-left: 57px"
                    src="./assets/storystyle/newStoryIcon/heartGray.png">
            </div>
            <div>
                <img (click)="shareBrand()" style="width:20px !important; margin-top: 11px" src="./assets/share_copy.png">
            </div>
        </div>
    </div>
    <div class="brand-name">
        <h6 class="text-white" (click)="navigateOnThread()">{{this.brandObject.BrandName}}</h6>
    </div>
    <div class="col-lg-12 garmentName">
        <div class="justify-content-center text-center d-flex set-margin">
            <h6 class="text-primary brandbtn  py-2 d-block color-class" (click)="setTab('story','S')">
                <div *ngIf="tab =='story'" [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                    <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
                </div>
                <img (click)="setTab('story','S')" *ngIf="tab != 'story' && tab != 'news-stories' "
                    [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                    style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
                <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy ms-3">
                    Stories</h6>
            </h6>
            <h6 class="text-primary brandbtn py-2 d-block color-class">
                <img *ngIf="tab == 'mens-wear'" [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                    (click)="setTab('mens-wear','M')" style="color:black ;padding-bottom: 5px;"
                    src="./assets/storystyle/MenIconBlack.png">
                <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                    [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''" style="color:black ;padding-bottom: 5px;"
                    src="./assets/storystyle/MenIconWhite.png">
                <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
            </h6>
            <h6 class="text-primary brandbtn py-2 d-block color-class">
                <img *ngIf="tab == 'womens-wear'" (click)="setTab('womens-wear','W')"
                    [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                    style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
                <img (click)="setTab('womens-wear','W')" *ngIf="tab != 'womens-wear'"
                    [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''" style="color:black ;padding-bottom: 5px;"
                    src="./assets/storystyle/WomenIconWhite.png">
                <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy  w-margin">Womenswear</h6>
            </h6>
        </div>
    </div>
    
    <div>
        <div class="flipFlag-class" id="scrollableDiv" #setclothBand>
    
            <div class="set-img-div" *ngIf="!swichImageFlage && clothDetails">
                <div *ngIf="isloading" class="d-flex justify-content-center" style="margin-top: 100px;">
                    <div class="spinner-border text-white" role="status">
                        <span class=""></span>
                    </div>
                </div>
                <img (click)="callGarmentCloth()" *ngIf="!isloading" class="img-responsive-flip-class"
                    [src]="this.source" />
            </div>
    
            <div *ngIf="swichImageFlage && clothDetails">
                <div class="" id="target" *ngIf="!isloading">
                    <app-twod-image [garmentImg]="this.source" (triggedCloth)="setSelectedGarmetCloth($event)"
                        [garmentType]="this.typeOfGarment" [pageType]="style"></app-twod-image>
                </div>
                <div class="d-flex justify-content-center" *ngIf="isloading">
                    <div class="spinner-border" role="status">
                        <span class=""></span>
                    </div>
                </div>
            </div>
    
            <div *ngIf="!clothDetails" class="text-center">
                <div class="spinner-border m-5 p-3" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
    
            <div class="setcloth-band">
                <div class="mb-2 text-black clothName-brand color-class-band" >
                    <div class="d-flex garment-title" (click)="callGarmentClothName()">
                        <div class="text-center d-inline-flex garmen-name-in-band">
                            <div class="">
                                <span *ngIf="RealType == '0'" class="brandbtn py-1"><img
                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                        src="./assets/realtype/headgear.png"></span>
                                <span *ngIf="RealType == '1'" class="brandbtn py-1 "><img style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6; 
                                margin-right: 4px;" src="./assets/realtype/upperwear.png"></span>
                                <span *ngIf="RealType == '2'" class="brandbtn py-1"><img
                                        style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                        src="./assets/realtype/bottomwear.png"></span>
                                <span *ngIf="RealType == '3'" class="brandbtn py-1"><img
                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                        src="./assets/realtype/footwear.png"></span>
                                <span *ngIf="RealType == '4'" class="brandbtn py-1"><img
                                        style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                        src="./assets/realtype/heeledfootwear.png"></span>
                                <span *ngIf="RealType == '5'" class="brandbtn py-1"><img
                                        style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                        src="./assets/realtype/fullbody.png"></span>
                            </div>
                            <div class="garment-name-class">
                                <h6 (click)="callGarmentClothName()" class="brand-font" style="overflow-wrap: break-word;">
                                    {{(selectedSnapShot).split()}}
                                </h6>
                            </div>
    
                        </div>
                        <div>
                            <div class="text-center band-3d-icon d-flex col-sm-1">
                                <h6 class=" text-primary  color-class">
                                    <img *ngIf="!garmentScrollFlag" (click)="switch3DImage($event)" class="threeD_icon "
                                        src="./assets/realtype/3d-paranoma.png">
                                    <img *ngIf="garmentScrollFlag" (click)="flipClose()" class="threeD_icon "
                                        src="./assets/realtype/paraCloseBlack.png">
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                        <h6 (click)="selectIcon('popup')" class="text-primary brandbtn m-1 d-block class">
                            <div *ngIf="selectedIcon == 'popup'" (click)="navigateOnThread()"
                                [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card-band'  : 'story-menu-icon'">
                                <img style="width: 12px;" src="./assets/storystyle/story-white.png">
                            </div>
                            <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                style="color:black ;margin-top: 0px; width: 10px;" src="./assets/storystyle/storyblack.png">
    
                            <h6 *ngIf="selectedIcon == 'popup'" class="selected-name p-1" (click)="navigateOnThread()">Story</h6>
                        </h6>
                        <h6 (click)="selectIcon('detail')" *ngIf="clothDetails?.ThumbCenterURL  != undefined"
                            class="m-1 brandbtn">
                            <div *ngIf="selectedIcon == 'detail'"
                                [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank">
                                    <img style="width: 12px; margin-top: 5px;" src="./assets/details.png"></a>
                            </div>
                            <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank"><img *ngIf="selectedIcon != 'detail'"
                                    [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''" style="color:black ;"
                                    src="./assets/storystyle/newStoryIcon/detailGray.png"></a>
    
                            <h6 *ngIf="selectedIcon == 'detail'" class="selected-name p-1">Details</h6>
                        </h6>
                        <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                            <a><img style="width:15px !important; color:black"
                                    src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                        </h6>
                        <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined"
                            class="m-1 brandbtn">
                            <div *ngIf="selectedIcon == 'gallary'"
                                [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank">
                                    <img style="width: 12px; margin-top: 5px;" src="./assets/gallary.png"></a>
                            </div>
                            <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank"><img *ngIf="selectedIcon != 'gallary'"
                                    [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''" style="color:black;"
                                    src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                            <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name p-1">Gallary</h6>
                        </h6>
                        <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn">
                            <a><img style="width:15px !important ;"
                                    src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                        </h6>
                        <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined"
                            class="m-1 brandbtn">
                            <div *ngIf="selectedIcon == 'cart'"
                                [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                <a href="{{clothDetails?.ThumbRightURL}}" target="_blank">
                                    <img style="width: 12px; margin-top: 5px;" src="./assets/shop.png"></a>
                            </div>
                            <a href="{{clothDetails?.ThumbRightURL}}" target="_blank"><img *ngIf="selectedIcon != 'cart'"
                                    [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                    style="color:black; width: 1px;"
                                    src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                            <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                        </h6>
                        <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn">
                            <a><img style="width:15px !important; color:black"
                                    src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                        </h6>
                    </div>
                </div>
            </div>
            <div class="img-detail-story">
                <div class="twoDImage d-block mb-2" *ngIf="clothDetails">
                    <!-- <div class="" *ngIf="userFileList != undefined">
                        <app-image-slide [images]="userFileList"></app-image-slide>
                    </div>
                    <div class="d-flex" *ngIf="userFileList == undefined || userFileList.length == 0">
                        <app-image-slide [images]="images"></app-image-slide>
                    </div> -->


                    <div class="" *ngIf="userFileList != undefined">
                        <!-- <app-image-slide [images]="userFileList"></app-image-slide> -->
                        <app-slider-two-d [images]="userFileList" [deleteGarmentUpload]="deleteflag"
                        [clothId]="clothDetails?.id"></app-slider-two-d>
                    </div>
                    <div *ngIf="userFiledetails3d != undefined">
                        <!-- <app-image-slide [images]="userFiledetails3d">
                        </app-image-slide> -->
                        <app-slider-two-d [images]="userFiledetails3d" [deleteGarmentUpload]="deleteflag"
                        [clothId]="clothDetails?.id"></app-slider-two-d>
                    </div>
                    <!-- <div *ngIf="userFiledetails3d != undefined"
                        class="text-center d-flex upload-image-slider-css-both twoDImage-both">
                        <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                style="height: 80px; width: 100px; float: none;">
                            </model-viewer>
                        </div>
                    </div> -->
                    <div class="d-flex justify-content-center ">
                        <span (click)="openothwod()"><img
                                style="width:30px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/upload-icon/2D.png"></span>
                        <span (click)="openothreed()" data-bs-toggle="modal" data-bs-target="#upload3D"><img
                                style="width:30px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/upload-icon/3D.png"></span>
                    </div>
    
                </div>
    
                <div *ngIf="!clothDetails" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
    
                <div (click)="callGarmentClothName()"
                    [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-2' : 'collection-details-sku3d details'">
                    <h6 class=" color-class "
                        *ngIf="clothDetails?.CollectionName != undefined && clothDetails?.CollectionName != '' ">
                        Collection: {{filterCollectionName(clothDetails.CollectionName)}}</h6>
                    <h6 class=" color-class "
                        *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">
                        Collection: None</h6>
                    <h6 class=" color-class" *ngIf="clothDetails?.Details != undefined &&  userFiledetails3d.length != 0">
                        {{(showMore) ? clothDetails .Details : clothDetails .Details | slice:0:120}}
                        <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                        <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                            (click)="showMore=true">Read More</a>
                        <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                            (click)="showMore=false">Read less</a>
                    </h6>
    
                    <h6 class="color-class" *ngIf="clothDetails?.Details != undefined  &&  userFiledetails3d.length == 0 ">
                        {{(showMore) ? clothDetails.Details : clothDetails .Details| slice:0:400}}
                        <span *ngIf="!showMore && clothDetails.Details.length < 400">...</span>
                        <a href="javascript:;"
                            *ngIf="!showMore && clothDetails.Details.length > 400  &&  userFiledetails3d.length == 0"
                            (click)="showMore=true">Read More</a>
                        <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                            (click)="showMore=false">Read less</a>
                    </h6>
                    <h6 class="color-class" *ngIf="clothDetails?.Details == undefined  &&  userFiledetails3d.length < 0 ">
                        {{(showMore) ? dummyText : dummyText | slice:0:150}} <span *ngIf="!showMore">...</span>
                        <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                            (click)="showMore=true">Read More</a>
                        <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                            (click)="showMore=false">Read less</a>
                    </h6>
                    <h6 class="color-class" *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length == 0">
                        Your brand's apparel is digitised in 3D
                        <br><br>
                        On a gamified experience, consumers style avatars & share animated avatar
                        stories
                        (linked to ecommerce)
                        <br><br>
                        Defining a new paradigm in immersive storytelling for social media & the
                        metaverse
                    </h6>
                    <h6 class="color-class"
                        *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0  && userFileList.length > 0">
                        {{(showMore) ? dummyText : dummyText}} "
                        <a href="javascript:;" *ngIf="!showMore &&  
    clothDetails .Details == undefined &&  
    userFiledetails3d.length > 0  && userFileList.length > 0 " (click)="showMore=true">Read More</a>
                        <a href="javascript:;"
                            *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFileList.length > 0"
                            (click)="showMore=false">Read less</a>
                    </h6>
    
                    <h6 class="color-class"
                        *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFileList.length == 0">
                        {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                            *ngIf="!showMore && clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFileList.length == 0"
                            (click)="showMore=true">Read More</a>
                        <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read less</a>
                    </h6>
                    <h6 class="color-class">SKU: {{clothDetails?.SKU}}</h6>
                </div>
                <div #storyContainer class="my-story" *ngIf="clothDetails" >
                    <div class="row p-1 justify-content-center d-flex" [style.height.px]="brandStoryScrollerHeight - 162"
                        *ngIf="userSnapShotListGarment?.length >= 0">
                        <div class="col-6 col-md-6" *ngFor="let snapshot of userSnapShotListGarment; let i = index;">
                            <img id="snapshot-img-{{i}}" (click)="selectGarmentImage(i,snapshot)" class="img-fluid rounded mb-1" [src]="snapshot.SnapshotFileUrlMicro">
                        </div>
                        <div class="col-12 text-center text-black justify-content-center">
                            <h6 (click)="incrementImgGarmentCard()" *ngIf="userSnapShotListGarment.length >= 12">
                                <b>More</b>
                            </h6>
                        </div>
                    </div>
                </div>
    
                <div *ngIf="!clothDetails" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>


