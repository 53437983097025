<app-public *ngIf="!logInFlag && menuFlag == undefined && mainMenuFlag == undefined"></app-public>
<app-yologram-social *ngIf="logInFlag && menuFlag == undefined && mainMenuFlag == undefined"></app-yologram-social>

<div (click)="closePanel()"   [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}">
    <div class="manage-w" [ngClass]="flagForShowLottieTemplet ? 'd-none' : ''">
        <div *ngIf="mainMenuFlag == undefined || teamplateFlag != undefined ">
            <div style="width: 290px; height: 470px;">
                <div
                    style="display: flex; justify-content: space-between; align-items: center; width: 100%;" [ngClass]="WinSizeCall && loadedCompoent === 'loaded' ? 'class-dropdown' : ''">
                    <div *ngIf="WinSizeCall && loadedCompoent === 'loaded'"    class="m-1" 
                        style="cursor: pointer; z-index: 0;">
                        <!-- <i class="bi bi-eye close-icon-x"></i> -->
                            <span class="d-block">

                                <img  (click)="closeThreeDBUTTON()" class="dummy-card" src="./assets/small-card.png" />
                                <img  (click)="closeThreeDBUTTON()" class="short-image-card" [src]="snapshot.SnapshotFileUrlMini" />
                                <img class="back-card" (click)="closeThreeDBUTTON()"
                                    style="width:20px !important; transform: scaleX(-1);" src="./assets/share_copy.png">
                                <img  (click)="handleAnimationList()" class="animation-icon" src="./assets/animation-icon-white.png" />  
                            </span>
   
                    </div>

                    <!-- <div class="text-center" style="flex-grow: 1; z-index: 0;"
                        *ngIf="( this.WinSizeCall) && loadedCompoent === 'loaded'">
                        <select name="animations" id="animations" class="custom-select-dropdown"
                            (change)="onSelectAnimation($event)">
                            <option value="" disabled selected>Select an animation</option>
                            <option *ngFor="let animation of animationCollection" [value]="animation.storageURL">
                                {{ animation.displayName}}
                            </option>
                        </select>
                    </div> -->

                    <div  class="text-center" style="flex-grow: 1; z-index: 0;"
                        *ngIf="(this.WinSizeCall) && loadedCompoent === 'loaded' && animationListShow">
                        <ul class="animation-list">
                            <li *ngFor="let animation of animationCollection" (click)="onSelectAnimation(animation.storageURL)"
                                class="animation-item">
                                {{ animation.displayName }}
                            </li>
                        </ul>
                    </div>


                    


                </div>
                <app-news3-d-templete [flagForThreeJs]="flagForShowThreeJs" [EnvUrl]="envUrl" [GarmentsGender]="genders"
                    [upperwearUrl]="garmenturl" [FunctionFlag]="functionflagValue" [findFlag]="isChecked"
                    [flag_v2]="flag_v2" [bvhAnimationUrl]="this.selectedAnimation"
                    (errorMessage)="setEroorMessage($event)" (avtarNotFoundMessege)="setAvtarNotFoundMessege($event)"
                    (calledFunction)="setAvatarAndClothLoaded($event)" [bottomwearUrl]="garmentbottom"
                    [BlobUrl]="dataBlobUrl" [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear"
                    [footwearUrl]="footwear" [StoryID]="storyID" [vrmAvatarUrl]="this.vrmAvtarUrl" [storyText]="dummytext">
                </app-news3-d-templete>
            </div>
        </div>
        
        <div class="card-position" [ngClass]=" this.WinSizeCall == true   ? 'd-none' : ''">
            <div class="allImage vertical-scroll-container card " #scrollableDiv (scroll)="onVerticalScroll()"
                [ngClass]=" this.WinSizeCall == true   ? 'd-none' : ''">
                <div class="image-container d-block" *ngFor="let snapshot of paginatedSnapshots; let i = index"
                    [id]="'element' + i">
                    <div class="horizontal-scroll-container" (scroll)="onHorizontalScroll($event, i)"
                        #horizontalScrollContainer    #target id="{{snapshot.StoryID}}">
                        <div class="red-background" style="align-content: center;" *ngIf="snapshot.SnapshotFileUrl == ''" >
                            <div class="spinner-border" style="margin-left: 40%;" role="status">
                            <span class="sr-only text-black" ></span>
                        </div>
                        </div> 
                        <div  *ngIf="snapshot.SnapshotFileUrl !=''" class="red-background" [id]="'element_card'+'Home' ">
                            <div class="small-box">
                                <div class="col-sm-12 d-flex">
                                    <div class="user-name">
                                        <div class="text-left font-weight-bold"
                                            (click)="openUserPanel(snapshot,$event)"><a>{{
                                                getDisplayName(snapshot)}}</a></div>
                                        <div class="text-left date-class">
                                            {{convertFirestoreTimestampToDate(snapshot.DateTime) |
                                            date: 'medium'}}</div>
                                    </div>
                                    <div class="user-avtar ">

                                        <div class="text-right date-class d-flex" (click)="openAvatarPanel(snapshot,$event)"> 
                                         <img style="width: 12px; height: 12px;" src="./assets/body.png"/>  {{snapshot?.avatarName == undefined ? this.getAvatartDetails(snapshot):
                                            ( snapshot?.avatarName != '' ? snapshot?.avatarName : ( snapshot?.avatarName
                                            == ''
                                            ||
                                            snapshot?.avatarName == undefined ? snapshot?.AvatarID : ''))}}
                                        </div> 
                                       

                                        <!-- <div class="text-right date-class" (click)="openAvatarPanel(snapshot,$event)">
                                            🧍🏽{{snapshot?.avatarName || snapshot?.AvatarID}}
                                        </div> -->



                                        <div class="text-right date-class"
                                            (click)="environmentPanel(snapshot.Environment,$event)">
                                            {{getEnvironmentName(snapshot.Environment)}}</div>
                                            
                                    </div>
                                </div>
                            </div>
                            <div class="spinner-border spin-loader" role="status"
                                *ngIf="snapshot.snapshotLoading != undefined && this.snapshot.snapshotLoading">
                                <span class="sr-only"></span>
                            </div>

                            <!-- <img [offset]="500" [scrollTarget]="scrollableDiv" [lazyLoad]="snapshot?.SnapshotFileUrlMini"
                        [defaultImage]="snapshot?.SnapshotFileUrlMicro || '/assets/no thumbnail.jpg'" [errorImage]="'path/to/error.jpg'"
                        [style.width.px]="imageWidth" [style.height.px]="imageHeight" /> -->

                            <!-- defaultImage="{{ snapshot?.SnapshotFileUrlMini || '/assets/no thumbnail.jpg' }}" -->

                            <div class="image-container-new" style="width: 290px; height: 345px;">
                            
                             <img [ngClass]="teamplateFlag == 1  ? 'snashot-watermarking': ''"  class="cropped-image-new"
                                defaultImage="{{ snapshot?.SnapshotFileUrlMini || '/assets/no thumbnail.jpg' }}"
                                appLazyLoad="{{ snapshot?.SnapshotFileUrl }}" [style.width.px]="imageWidth"
                                [style.height.px]="imageHeight" [snapshot]="snapshot"/>

                                <img    [ngClass]="teamplateFlag == 2  ? '': 'd-none' " class="img-fluid fram-IGS"  width="345 !important" height="280"
                                src="./assets/realtype/iDGS-small_font.png" >

                                <div *ngIf="teamplateFlag == 1 " >
                                    <div *ngIf="teamplateFlag == 1" class="story-heading">Business Today</div>
                                    <!-- <div  *ngIf="teamplateFlag == 3"  class="story-heading">IDGS</div> -->
                                    <div class="bottom-color-box-left">&nbsp;</div>
                                    <div class="bottom-color-box-right">&nbsp;</div>
                                </div>
                            </div>



                            <div class="threeD-image" *ngIf="showloadhingFlage">
                                <div class="spinner-border new-spin-loader" role="status">
                                    <span class="sr-only"
                                        style="width:20px !important; height: 20px; margin-top: -100px;"></span>
                                </div>
                            </div>

                            <div class="threeD-image"
                                *ngIf="snapshot.vrmURL && !showloadhingFlage && snapshot.snapshotLoading == '' ">
                                <img (click)="openNews3DPassVRMValue(copyTextToClipEven(snapshot.Environment),
                            copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                            (snapshot),
                            copyTextToClipGarment(snapshot.UpperwearID),
                            copyTextToClipGarment(snapshot.BottomwearID),
                            copyTextToClipGarment(snapshot.FootwearID), 
                            copyTextToClipGarment(snapshot.HeadwearID)
                            ,$event)" style="width:20px !important; height: 20px; margin-top: -50px; "
                                    src="./assets/realtype/VRMFiles.png">
                            </div>

                            
                            <div class="threeD-image" *ngIf="!showloadhingFlage && snapshot.snapshotLoading == ''">
                                <img (click)="openNews3DPassValue(copyTextToClipEven(snapshot.Environment),
                    copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                    (snapshot),
                    copyTextToClipGarment(snapshot.UpperwearID),
                    copyTextToClipGarment(snapshot.BottomwearID),
                    copyTextToClipGarment(snapshot.FootwearID), 
                    copyTextToClipGarment(snapshot.HeadwearID),$event
                    )" style="width:20px !important; height: 20px; " src="./assets/3d-movie.png">
                            </div>

                            <span class="lotti-animation"
                                *ngIf="snapshot.lottieURL != '' && snapshot.snapshotLoading == ''">
                                <!-- <img style="width:20px !important; height: 20px;"
                                    src="./assets/realtype/LottieFiles.png"
                                    (click)="showLottieFiles(snapshot.lottieURL,snapshot.SnapshotFileUrlMini)"> -->
                                    <img style="width:30px !important; height: 30px;"
                                    src="./assets/360-degrees-8.png"
                                    (click)="showLottieFiles(snapshot.lottieURL,snapshot.SnapshotFileUrlMini)">
                                <!-- <i style="font-size:20px;   margin-left: 250px; " class="bi bi-binoculars" (click)="showLottieFiles(snaphot.lottieURL)"></i> -->
                        </span>
                            <div class="second-share" *ngIf="this.pageFlagRout != 'edit-news'"><img (click)="openShareModal(snapshot)"
                                    style="width:20px !important;" src="./assets/share_copy.png">
                            </div>


                           
                            <div class="search-icon" *ngIf="this.pageFlagRout == 'edit-news'" (click)="openSearchPanel(snapshot,$event)">
                                <img
                                    style="width:20px !important;" src="./assets/brand-space/search-button.png">
                                <!-- <i  style="color: black; font-size: 18px; text-shadow: 0 0 2px white, 0 0 4px white;" class="bi bi-search"></i> -->
                            </div>


                            <div class="second-share"  *ngIf="this.pageFlagRout == 'edit-news'">
                                <a class="" (click)="deleteSnapshotFile(snapshot.id)">
                                    
                                    <!-- <i  style="color: black; font-size: 18px; text-shadow: 0 0 2px white, 0 0 4px white;" class="bi bi-trash-fill">

                                    </i> -->
                                    <img
                                    style="width:20px !important;" src="./assets/brand-space/bin-save.png">
                                
                                </a>
                            </div>


                            <div class="large-box">
                                <div class="col-12 col-sm-12  d-flex " *ngIf="paginatedSnapshots">
                                    <div class="col-2 col-sm-2">
                                        <img class="m-1" (click)="openBrandPanel(snapshot.HeadwearID,'brand',$event)"
                                            style="width:45px; height:45px !important; color:black;"
                                            *ngIf="getGarmentrealtype(snapshot .HeadwearID) == '0' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(snapshot .HeadwearID)"/>
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="snapshot.HeadwearID != undefined && snapshot .HeadwearID != ''">
                                        <img (click)="openGarmentNav(snapshot,'HeadwearID',snapshot.HeadwearID,'headgear',$event)"
                                            class="gar-icon" *ngIf="getGarmentrealtype(snapshot .HeadwearID) == '0' "
                                            style="width:15px !important; color:black ; opacity: 0.4;"
                                            src="./assets/newsfeed/headgear-new.png">
                                        <span
                                            (click)="openGarmentNav(snapshot,'HeadwearID',snapshot.HeadwearID,'headgear',$event)"
                                            class="caption-values"
                                            *ngIf="snapshot .HeadwearID != undefined && snapshot .HeadwearID != ''">
                                            <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                title="Cloth Details">
                                                {{getGarmentName(snapshot.HeadwearID).substring(0,25)}}
                                            </span>&nbsp;
                                        </span>
                                        <span
                                            *ngIf="!showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                            <a class="pintrest-icon"
                                                *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                                                (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                        <a (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                            class="pintrest-icon"
                                            *ngIf="showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12  d-flex" *ngIf="paginatedSnapshots">
                                    <div class="col-2 col-sm-2 ">
                                        <img (click)="openBrandPanel(snapshot.UpperwearID,'brand',$event)" class="m-1"
                                            style="width:45px; height:45px !important; color:black ; "
                                            *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '1' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(snapshot .UpperwearID)" />
                                        <img (click)="openBrandPanel(snapshot.UpperwearID,'brand',$event)" class="m-1"
                                            style="width:45px; height:45px !important; color:black ;"
                                            *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '5' "
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(snapshot .UpperwearID)" />
                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="snapshot .UpperwearID != undefined && snapshot .UpperwearID != ''">
                                        <span
                                            (click)="openGarmentNav(snapshot,'UpperwearID',snapshot.UpperwearID,'upperwear',$event)">
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                                style="width:15px !important; color:black; opacity: 0.4;"
                                                src="./assets/realtype/upperwear.png">
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '5' "
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/realtype/fullbody.png">
                                            <span class="caption-values"
                                                *ngIf="snapshot .UpperwearID != undefined && snapshot .UpperwearID != ''">
                                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                    title="Cloth Details">
                                                    {{getGarmentName(snapshot .UpperwearID).substring(0,25)}}
                                                </span>&nbsp;
                                            </span>
                                        </span>
                                        <span
                                            *ngIf="!showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                            <a class="pintrest-icon"
                                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID)"
                                                (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" /></a>
                                        </span>
                                        <a (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                            class="pintrest-icon"
                                            *ngIf="showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                        </a>

                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 d-flex " *ngIf="paginatedSnapshots">
                                    <div class="col-2 col-sm-2 ">
                                        <img (click)="openBrandPanel(snapshot.BottomwearID,'brand',$event)" class="m-1"
                                            style="width:45px; height:45px !important; color:black ; "
                                            *ngIf="getGarmentrealtype(snapshot .BottomwearID) == '2'"
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(snapshot .BottomwearID)" />

                                    </div>
                                    <div class="col-8 col-sm-8 clothName p-1"
                                        *ngIf="snapshot .BottomwearID != undefined && snapshot .BottomwearID != ''">
                                        <span
                                            (click)="openGarmentNav(snapshot,'BottomwearID',snapshot.BottomwearID,'bottomwear',$event)">


                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(snapshot .BottomwearID) == '2'"
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/newsfeed/bottomwear-new.png">
                                            <span class="caption-values"
                                                *ngIf="snapshot .BottomwearID != undefined && snapshot .BottomwearID != ''">
                                                <span class="garment-name" data-toggle="popover" data-placement="left"
                                                    title="Cloth Details">{{getGarmentName(snapshot.BottomwearID).substring(0,20)}}
                                                </span>&nbsp;
                                            </span>
                                        </span>
                                        <!-- <a class="pintrest-icon"
                                    *ngIf=" snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                    (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                                        src="./assets/Pinterest-logo.png" />
                                </a> -->

                                        <span
                                            *ngIf="!showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                            <a class="pintrest-icon"
                                                *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                                (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                        <a (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                            class="pintrest-icon"
                                            *ngIf="showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12  d-flex" *ngIf="paginatedSnapshots">
                                    <div class="col-2 col-sm-2 ">
                                        <img (click)="openBrandPanel(snapshot.FootwearID,'brand',$event)" class="m-1"
                                            style="width:45px; height:45px !important; color:black ;"
                                            *ngIf="getGarmentrealtype(snapshot .FootwearID) == '3'"
                                            class="rounded profileImg-brand"
                                            [src]="getBrandImageByGarmetnID(snapshot .FootwearID)" />
                                    </div>

                                    <div class="col-8 col-sm-8 clothName p-1"
                                        (click)="openGarmentNav(snapshot,'FootwearID',snapshot.FootwearID,'footwear',$event)"
                                        *ngIf="snapshot .BottomwearID != undefined && snapshot.FootwearID != ''">
                                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot .FootwearID) == '3' "
                                            style="width:15px !important; color:black ; opacity: 0.4;"
                                            src="./assets/realtype/footwear.png">
                                        <span class="caption-values"
                                            *ngIf="snapshot .FootwearID != undefined && snapshot.FootwearID != ''">
                                            <span class="garment-name" data-toggle="popover" data-placement="left"
                                                title="Cloth Details">
                                                {{getGarmentName(snapshot .FootwearID).substring(0,25)}}
                                            </span>&nbsp;
                                        </span>
                                        <!-- <a class="pintrest-icon"
                                    *ngIf=" snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                    (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                                        src="./assets/Pinterest-logo.png" />
                                </a> -->

                                        <span
                                            *ngIf="!showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                            <a class="pintrest-icon"
                                                *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                                (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                        <a (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                            class="pintrest-icon"
                                            *ngIf="showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  class="red-background"
                            *ngIf="snapshot.HeadwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                            [id]="'element_card_' + snapshot.HeadwearID">
                            <div class="scroll-item garment-card " [id]="HeadwearID">
                                <div class="swip-card-size p-2 m-2 ">
                                    <app-swipe-popup [key]="HeadwearID" [garmentFlagDummy]="dummyFlag"
                                        [garmentId]="snapshot.HeadwearID"
                                        (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                </div>
                            </div>
                        </div>
                        <div class="red-background" #upperwearCard
                            *ngIf="snapshot.UpperwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                            [id]="'element_card' + snapshot.UpperwearID">
                            <div class="scroll-item garment-card" [id]="UpperwearID">
                                <div class="swip-card-size p-2 m-2">
                                    <app-swipe-popup [key]="UpperwearID" [garmentFlagDummy]="dummyFlag"
                                        [garmentId]="snapshot.UpperwearID"
                                        (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                </div>
                            </div>
                        </div>
                        <div class="red-background"
                            *ngIf="snapshot.BottomwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                            [id]="'element_card' + snapshot.BottomwearID">
                            <div class="scroll-item garment-card" [id]="BottomwearID">
                                <div class="swip-card-size p-2 m-2 ">
                                    <app-swipe-popup [key]="BottomwearID" [garmentFlagDummy]="dummyFlag"
                                        [garmentId]="snapshot.BottomwearID"
                                        (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                </div>
                            </div>
                        </div>
                        <div class="red-background"
                            *ngIf="snapshot.FootwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                            [id]="'element_card' + snapshot.FootwearID">
                            <div class="scroll-item garment-card" [id]="FootwearID">
                                <div class="swip-card-size  p-2 m-2">
                                    <app-swipe-popup [key]="FootwearID" [garmentFlagDummy]="dummyFlag"
                                        [garmentId]="snapshot.FootwearID"
                                        (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center dots-container" style="margin-left: 150px;" *ngIf="mostVisibleIndex == i">
                        <div class="horizontal-dots">
                            <span class="dot" *ngFor="let item of getHorizontalItems(snapshot); let i = index"
                                [class.active-card]="horizonatlVisibleIndex == i" (click)="scrollToCard(item)"></span>
                        </div>
                        <button *ngIf="showManualLoadButton" (click)="moreButtonForNext()">Load More</button>
                    </div>

                    <div class="text-center m-1" *ngIf="snapshot.SnapshotFileUrlMini  == undefined">
                        <button type="button" class="btn btn-secondary" (click)="updateThumbNail(snapshot,i)"
                            [disabled]="(snapshot?.SnapshotFileUrlMicro !== '' && 
                snapshot?.SnapshotFileUrlMini) && (snapshot?.SnapshotFileUrlMini !== undefined && snapshot?.SnapshotFileUrlMini !== '') ">
                            Generate Thumbnail
                        </button>
                        <div class="spinner-border" role="status" *ngIf="thumbnilflag">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>

                <div class="brand-close" id="closePannel" *ngIf="showpanel || mainMenuFlag == undefined"
                    (click)=" clickOnPanel($event)">
                    <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight" *ngIf="closeDiv && !wholeDiv">

                        <div *ngIf="userCollapseFlag">
                            <app-user-panel [UserShapShotObject]="this.snapshot" [UserID]="this.snapshot.UserID"
                                [brandStoryScrollerHeight]="brandStoryScrollerHeight" [userData]="userData" [UserData]="UserData"
                                [selectedUserName]="selectedUserName">
                            </app-user-panel>
                        </div>

                        <div *ngIf="avatarCollapseFlag">
                            <app-avatar-panel [UserShapShotObject]="this.snapshot" [UserID]="this.snapshot.UserID"
                                [selectedUserName]="selectedUserName" [selectedAvatarName]="selectedAvatarName"
                                [brandStoryScrollerHeight]="brandStoryScrollerHeight" [userData]="userData" [AvatarData]="AvatarData">
                            </app-avatar-panel>
                        </div>

                        <div *ngIf="evenCollapseFlag">
                            <app-environment-panel [gltfImgSrc]="gltfImgSrc" [skyBoXall]="skyBoXall"
                                [twoDprivew]="twoDprivew" [evsname]="evsname" [evsdiplaynameALL]="evsdiplaynameALL"
                                [environmentFileName]="environmentFileName" [hideFlag]="hideFlag"
                                [brandStoryScrollerHeight]="brandStoryScrollerHeight-40" [environmentData]="environmentData"></app-environment-panel>
                        </div>

                        <div *ngIf="germentCollapseFlag">
                            <app-drips-garment-panel [garmentId]="selecteGarmentId" [typeOfGarment]="typeOfGarment" 
                                [selectedSnapShot]="selectedGarmentName" [brandUrl]="brandUrl" [RealType]="realType" [garmentData]="garmentData"
                                [typeWear]="garmentTypeID">
                            </app-drips-garment-panel>
                        </div>


                        <div *ngIf="brandCollapseFlag ">
                            <app-drips-brand-panel [newselectedBrandId]="selectedBrandId" [brandData]="brandData"
                                [newbrandUrl]="newbrandUrl" [brandStoryScrollerHeight]="brandStoryScrollerHeight "></app-drips-brand-panel>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="loader-overlay" *ngIf="loadPanelLoader">
        <div class="loader"></div>
    </div> 
    
    <div [ngClass]="!flagForShowLottieTemplet ? 'd-none' : ''">
        <div class="m-1" (click)="closeLottieTemplate()" style="cursor: pointer; z-index: 0;"><i
                class="bi bi-x close-icon-x"></i></div>
        <app-lottie-file-templet [LottieURL]="lottieURL" [SnapURL]="snapSingleUrl"></app-lottie-file-templet>
    </div>

    <div *ngIf="WinSizeCall && loadedCompoent == 'loaded'" class="btn-resize-dwon d-flex">
        <div *ngIf="loadedCompoent == 'loaded' && WinSizeCall" class="story-model-expand"
            (click)="switchFlagforFocus()">
            <span><button class="story" [ngClass]="{ 'active': isCheckedFocus === true}">Story</button></span>
            <span><button class="model" [ngClass]="{ 'active': isCheckedFocus === false}">Model</button></span>
        </div>
    </div>
</div>

