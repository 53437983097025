import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { PopupThreeDComponent } from '../../popup-three-d/popup-three-d.component';
import { HttpClient } from '@angular/common/http';
import { TextureLoader } from 'three';

@Component({
  selector: 'app-environment-panel',
  templateUrl: './environment-panel.component.html',
  styleUrls: ['./environment-panel.component.css']
})
export class EnvironmentPanelComponent {


  // @Input() gltfImgSrc: string | undefined;
  @Input() skyBoXall: string | undefined;
  @Input() twoDprivew: string | undefined;
  @Input() evsname: string | undefined;
  @Input() evsdiplaynameALL: string | undefined;
  @Input() environmentFileName: string | undefined;
  @Input() hideFlag: boolean | undefined;
  @Input() fileDeletFlag: boolean | undefined;
  @Input() fileObject: any = {}
  @Input() routeFlageEven: string | undefined;
  @Input() brandStoryScrollerHeight: any;
  @Input() environmentData: any;
  @Input() evndata: any;
  @Input() pageiIndex: any;
  @Input() callpageNumer: any;
  
  
  @Input() evnProfilePageFlag : boolean | undefined;

  @Output() SnapStoryId: EventEmitter<any> = new EventEmitter();
  @Output() evnCompressUrl: EventEmitter<any> = new EventEmitter();

  

  
  @Output() closeEvenEmitter = new EventEmitter<any>();
  @Output() previousEvenEmitter = new EventEmitter<any>();
  


  gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';






  srcfile = './assets/scene.gltf'
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  SnapShotFileDetails: any = []
  evnFileListFileName: any = []
  evnFileList: any = []
  filterEnvironmentSnpShot: any = []
  snapshot: any;
  userSnapShotList: any = []
  userSnapShotListSlice: any = []
  snaploader2D: boolean = false
  snaploader: boolean = false
  nameFlag: boolean = true;
  fileName: any;
  pageNumber: number = 1;
  newCompressImages: any = {};
  pagecount: number = 1;
  showChild: boolean;
  @Input() closebuttonFlag: boolean;
  evnData: any;
  snaploaderSnap: boolean = false;
  evnUrl: any;

  
  // gltfImgSrc  :any = "https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945"

  constructor(public dialog: MatDialog,

    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    private firestore: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    private modal: NgbModal,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private scrollService: CommonService,) {
    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
        let envFileName = this.evsname
        let evenListName = "Environments/"
        this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + envFileName)
      });



  }






  ngOnInit(): void {
    console.log(this.fileDeletFlag)
    console.log(this.fileObject)
    this.snaploaderSnap = true;
    this.printInputsToConsole()

    // setInterval(() => {
    //   this.snaploader2D = false;
    // }, 2000);

    setInterval(() => {
      // this.snaploader = false;
    }, 3000);

    // let reqInputObject = { id: this.evsname, pageNumber: 1 };
    // console.log("reqInputObject", reqInputObject)
    // this.commonService
    //   .yologramSnapshotEnvironment(reqInputObject)
    //   .subscribe(async (evn: any) => {
    //     this.userSnapShotListSlice = evn.object;
    //     console.log("userSnapShotListSlice", this.userSnapShotListSlice)
    //     this.pagecount = evn.count

    //   });

    // this.getCompressImage()

  }
  scrollToId(id: string) {
    if (id != '') this.scrollService.scrollToElementById(id);
  }

  async environmentPanelDataFunction(storageUrl){
    let functionUrl = " https://us-central1-yolomoves-fb435.cloudfunctions.net/observeEnvironmentsByStorageURL";
    return this.http.post<any>(functionUrl, { storageURL: storageUrl}).toPromise();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("evsname",this.environmentData)

    if (changes['evsname']) {
      console.log("evsname",this.evsname)
    }

    
    if (changes['environmentData']) {
      console.log("evsname",this.environmentData)
      this.loadData();

    }

    if (changes['evndata']) {
      this.environmentData = this.evndata.evenWholeData
      console.log("evsname",this.environmentData)
      this.loadData();

     


    }

    

  }

  // async loadBrandData() {
  //   let storageUrl = this.evsname
  //   let data = await this.environmentPanelDataFunction(storageUrl);
  //   console.log(data,storageUrl)
  //   // let image = await this.commonService.compressImage(this.evnData.skybox);
  //   this.newCompressImages = this.evnData.skybox;
  //   // this.getCompressImage()
  
  // if(this.newCompressImages){
  //   this.evnData = data.documents[0]
  //   this.userSnapShotListSlice = data.snapData.object
  //   this.snaploader2D = false;
  //   this.snaploader = false;
  // }
  // let image = await this.commonService.compressImage(this.evnData.skybox);
  // console.log("image", image);
  // }

  async loadData() {
    try {
      if(this.environmentData.lenghth != 0 ){
      this.evnData =  this.environmentData.documents[0]; // Assuming you want the first document
      this.userSnapShotListSlice =  this.environmentData.snapData.object;

      let evnFileName = this.environmentData.snapData.object[0].Environment
      let evnpath = 'Environment/'+evnFileName
      let type = 'e'
      this.newCompressImages = await this.commonService.compressImage(evnpath,type);
      this.snaploaderSnap = false
      }else{
        this.snaploaderSnap = true
        let storageUrl = this.evsname; // Assuming this.evsname is correctly set
        let data = await this.environmentPanelDataFunction(storageUrl);
        console.log('Fetched data:', data);
        if (data.documents.length > 0) {
          this.evnData = data.documents[0]; // Assuming you want the first document
      let evnFileName = this.evnData.snapData.object[0].Environment
      let evnpath = 'Environment/'+evnFileName
      let type = 'e'
          this.newCompressImages = await this.commonService.compressImage(evnpath,type);
         
          this.userSnapShotListSlice = data.snapData.object;
          this.snaploaderSnap = false
          console.log("this.evnData",this.evnData)

        } else {
          console.warn('No documents found.');
        }
      }
    } catch (error) {
      console.error('Error loading brand data:', error);
      // Handle error gracefully, e.g., show error message to user
    }
  }
  

  async getCompressImage() {
    // this.snaploader2D = true;
    // this.snaploader = true;
    let evnFileName = this.environmentData.snapData.object[0].Environment
    let evnpath = 'Environment/'+evnFileName
    let type = 'e'
    
    let image = await this.commonService.compressImage(evnpath,type);
    this.newCompressImages = image;
    console.log("image", image);
    this.snaploader2D = false;
    this.snaploader = false;

    // Assuming the correct variable is this.newCompressImages based on the assignment above
    console.log("newCompressImages", this.newCompressImages);
    console.log("newCompressImages['downloadUrl']", this.newCompressImages['downloadUrl']);
    console.log("newCompressImages.downloadUrl", this.newCompressImages.downloadUrl);
  }

  getEnvironmentProfile() {
    this.evnUrl = ''
    if(this.evnProfilePageFlag){
      this.evnUrl = this.newCompressImages.downloadUrl
      this.evnCompressUrl.emit(this.evnUrl)
      return
    }
    const previousUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (previousUrl.includes('/spaces') && this.router.url.includes('/spaces')) {
          window.location.reload();
        }
      }
    });
    let envFileName = this.evsname
    let evenListName = "Environments/"
    let obj = this.evnData['storageURL'];


// Remove the "Environments/" part
let fileName = obj.replace("Environments/", "");
// Remove the file name and keep only the directory path
    // console.log("envFileName", envFileName)
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL ==  this.evnData['storageURL'])
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    // this.router.navigate([this.routeFlageEven != undefined ? this.routeFlageEven  :  '/environment', {  'evnFileName':this.environmentFileName || envFileName,'evnFile': this.evnFileListFileName.displayName,'evnFileImage': this.skyBoXall,'envName': envFileName ,'storyFeed':'story' }])
    this.router.navigate(['/spaces', { 'evnFileName': fileName, 'evnFile': this.evnFileListFileName.displayName, 'evnFileImage': '', 'envName': fileName, 'storyFeed': 'story' }])
    console.log('this.skyBoXall', this.skyBoXall)
  }

  getEnvironmentProfileFeed(snapShot,index) {
    let selectedStoryId = snapShot.StoryID
    this.SnapStoryId.emit(selectedStoryId)
    // if(this.evnProfilePageFlag){
    //   return
    // }
     
    
    let evnName = snapShot.Environment
    let envFileName = this.evsname
    let obj = this.evnData['storageURL'];
    let storyId = snapShot.StoryID
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    let evenListName = "Environments/"
    let fileName = obj.replace("Environments/", "");

    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == obj)
    let reqInputObject = { id: envFileName, pageNumber: 1 }
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    this.router.navigate(['/spaces', { 'storyId': snapShot.StoryID, 'evnFileName': fileName, 'envName': fileName, 'evnFile':this.evnFileListFileName?.displayName , 'evnFileImage': '', 'storyFeed': 'storyFeed','index':index,'pageNumber':currentPage}])
    console.log('this.skyBoXall', this.skyBoXall)
  }

  

  editFileName($event) {
    this.fileName = $event.target.value
  }
  updateFileName(data) {
    this.nameFlag = true
    let object = {
      'filename': this.fileName
    }
    this.firestore.collection('userfiles').doc(data["id"]).update(object);
  }

  selectName() {
    this.nameFlag = false
  }

  deleteSnapshotFile(data: any) {
    console.log(data)
    if (this.fileDeletFlag) {
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = data.filepath
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    } else {
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = data.filepath
        // let filepath = "Uploads/" + data.userId +"/"+ data.filename
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    }
  }

  incrementImgCounter() {
    this.pageNumber++
    let evnNameFileName = this.environmentData.snapData.object[0].Environment
    console.log('Evn File name', this.environmentData.snapData.object[0].Environment)
    let reqInputObject = { id: evnNameFileName, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotListSlice.push(...response.object)
    })
  }

  toggleChild() {
    this.showChild = !this.showChild;
    this.closeEvenEmitter.emit(this.showChild)
  }

  previousbutton() {
    this.previousEvenEmitter.emit(true)
  }

  printInputsToConsole(): void {
    console.log('gltfImgSrc:', this.gltfImgSrc);
    console.log('skyBoXall:', this.skyBoXall);
    console.log('twoDprivew:', this.twoDprivew);
    console.log('evsname:', this.evsname);
    console.log('evsdiplaynameALL:', this.evsdiplaynameALL);
    console.log('environmentFileName:', this.environmentFileName);
    console.log('hideFlag:', this.hideFlag);
    console.log('fileDeletFlag:', this.fileDeletFlag);
    console.log('fileObject:', this.fileObject);
    console.log('routeFlageEven:', this.routeFlageEven);
    console.log('brandStoryScrollerHeight:', this.brandStoryScrollerHeight);
  }
}

