import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, finalize, map, Observable, Subscription, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { generateUUID } from 'three/src/math/MathUtils';
import { DatePipe } from '@angular/common';
import { Modal } from 'bootstrap';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;
  userFiles = this.firestore.collection("SnapshotDetails");
  userFileStateChange = this.userFiles.valueChanges();
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;



  clocthCollectionIdcollection: any = []
  selectedAvtar: any
  clothShapShotsList: any = []
  filterStories = []
  brandGarmentIds: any = [];
  BrandDetails: any = []
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  offsetPrevious = 0;
  scrolling = 'down';
  scrollingWidth = 50;
  indexMapping = 0;
  scrollPixel = 0;
  private timeout: any;
  snapshotCollection: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  brandsCollection: any = [];
  storyPinterestPinsList: any = [];
  subscription: Subscription | undefined;
  scrollselected: any = [];
  identifierList: any = {};
  slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
  clothDetails: any = {};
  selectedBrand: any = {};
  brandDetails: any;
  brands: any = [];
  boardId: any;
  boards: any;
  pinImg: string = "";
  isLoading = false;
  isLoading_2 = false;
  brandCollectionIdcollection: any = []
  brandhShapShotsList: any = []
  filterSnapShotForProfile: any = []
  allUserDetails: any = []
  profileDetails: any = {}
  enViromentName: any
  evnName: any = {}
  envFileName: any = {}
  evnNAME: any
  evnNAMEListNAme: any
  userid: any
  hideFlag: boolean = true
  showStoryCard: boolean = true
  snapInitCall: boolean = true



  pindetails: any = [];

  garmentMap: { [key: string]: string[] } = {}; // Global property to store garment IDs categorized by type


  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }

  germentCollapseFlag = false
  dropdownOptions = [
    { label: 'User', value: 'User' },
    { label: 'Garment', value: 'Garment' },
    { label: 'Brand', value: 'Brand' },
    { label: 'Avatar', value: 'Avatar' },
    { label: 'Env', value: 'Env' }
  ];

  v1: string | null = null;
  v2: string | null = null;
  v3: string | null = null;
  v4: string | null = null;

  searchFields = [
    { selectedOption: '', inputValue: '', isDropdownOpen: false }  // Initially show one dropdown and input
  ];


  imageList: any = this.pinImg;
  BrandId: any;
  brandId: any;
  StoryID: any;
  UpperwearId: any;
  pinId: any;
  loading = false;
  typeSelected: any;
  boardsName: any;
  SnapShotFileDetails: any = []
  id: any;
  deleteAccessFlag = false
  userId: string;
  storyId: any;
  topScroll: number;
  filterSnapShotFileDetails: any = []
  filterEnvironmentSnpShot: any = []
  evnFileList: any = []
  evnFileListFileName: any
  evnFiledefault: any
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  filelist: any = [];
  userData: any = [];
  counter: number = 4;
  showImages: any = {}
  profileName: any = {};
  rootForNAvigation: any = 'yologram-social/yologram-user-profile-feed'
  routLogInFlag: any = true
  images = [
    { "image": "./assets/user-upload-dummy/img-1.jpg" },
    { "image": "./assets/user-upload-dummy/img-2.jpg" },
    { "image": "./assets/user-upload-dummy/img-3.jpg" },
    { "image": "./assets/user-upload-dummy/img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-1.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpeg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpeg" },
  ]
  selectedId: any;
  usearDataList: any;
  gltfImgSrc: string;
  environmentFileName: any;
  skyBoXall: any;
  twoDprivew: string;
  evsdiplaynameALL: any;
  logInFlag: boolean;
  sideNavHeight: any;
  garmentId: any;
  tiletype: any;
  filetype: any;
  closeDiv: boolean;
  wholeDiv: boolean;
  selecteGarmentId: any;
  typeOfGarment: any;
  garmentTypeID: any;
  selectedGarmentName: string;
  garmentData: any = [];
  brandUrl: any;
  realType: string;
  garmentDataCache = {};
  garmentDataCacheOrder = [];
  editGarmentDetails: any;
  collectionObject: any = {};
  collectionName: any;

  collectionData: any = [];
  collectionDetails: any
  linkId: any;
  selectedOption: any;
  filterdataGarmentCollection: any;
  collectionFilterData: any = [];


  clothId: any;
  uploadFileName: any;
  envName: any = ''
  file: any;
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  hideName: boolean;
  showSearchPanel = false
  inputValue: any;
  userSnap: any = [];
  userSnafilter: any = [];
  userOption: any;
  garmentOption: any;
  brand: any;
  brandOption : any;
  Brand: any;
  User: any;
  garment: any;
  paginatedSnapshots: any = [];
  snapshots: any = [];
  endIdx: number = 0;
  next: number = 1;
  snapFiltered: any = [];
  lastfeild: any;
  searchTerms: any = [];
  brandFilter: any;
  storyFilterFlag: boolean;
  envOption: any;
  avatarOption: any;
  avatar: any;
  env: any;
  singleSnap: any;


  //  identifierDetail
  constructor(private storage: AngularFireStorage,
    private router: Router,
    public commonService: CommonService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    private apiHttpService: ApiHttpService,
    private pinterestAPI: PinterestService,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private modal: NgbModal,
    private http: HttpClient,
    public datepipe: DatePipe,

  ) {
    this.snapInit()
    this.commonService.getUser().subscribe((user: any) => {
      this.id = user?.uid
      let userObject = { 'userid': user?.uid, 'pagename': 'deleteSnapshotFile' }
      this.commonService.checkViewPageRights(userObject).subscribe((res: boolean) => {
        this.deleteAccessFlag = res
      });
    });


    this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
      this.userSnap = snapshots?.object
      await Promise.all(this.userSnap.map((snapShot) => this.getAvatartDetails(snapShot)));
      // console.log('Updated Snapshots:', this.userSnap);
    });




    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });


    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });


    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });


    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
      });



    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist

    })

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })

    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata
    })

    this.commonService.getUser().subscribe((user: any) => {
      this.userid = user?.uid;
      this.id
    })

    this.user.subscribe(userdetails => {
      this.userData = userdetails
      this.userDetails()
    });


  }

  ngOnInit(): void {

    let title = 'Yologram - edit-news'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.slides[0] = {
      src: 'snapshot.SnapshotFileUrl',
    };

    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.storyId = paramMap.get('storyId');
      this.BrandId = paramMap.get('brandId');
      this.getSnapShotFiles();
    });

    this.getPinterestBoards();
    this.checkPageAuthorization()
    this.getAllUserPRofile()
    this.setBrandStoryScrollerHeight()

  }


  userDetails() {
    this.usearDataList = this.userData.find((user) => user.UserID == this.id)
    console.log(this.usearDataList)
  }

  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }
  getAllUserPRofile() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      // this.snapShotInit()
    })
  }

  getProfileNameSetails(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid.UserID)
    this.profileName = userObjet

  }


  getSnapShotFiles() {
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      if (this.userId != undefined && this.userId != null) {
        this.SnapShotFileDetails = snapshot.filter((snapshotObject) => snapshotObject.UserID == this.userId);
      } else {
        this.SnapShotFileDetails = snapshot;
      }
      this.SnapShotFileDetails.forEach(async (snap: any, index) => {
        if (this.storyId != undefined && this.storyId === snap.StoryID) {
          this.topScroll = index * 700;
        }
        // let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + snap.UserID;
        // await this.apiHttpService.get(url).subscribe((data: any) => {
        //   snap['profileName'] = data.displayName != undefined ? data.displayName : snap.UserID;
        //   this.displayProgressSpinner = false;
        //   snap.firstName =  snap.profileName.split(" ", 1); 
        //   snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
        // },
        //   ((error) => {
        //     console.log(error);
        //   }
        //   ));
        // let UserProfileObject =  this.userData.find((user)=> user.UserID == snap.UserID )
        // let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        // snap.profileName = UserProfileObject.DisplayName != undefined ? UserProfileObject.DisplayName : (userObjet.displayName != undefined ? userObjet.displayName : snap.UserID);
        // userObjet.profileName = snap.profileName;
        // snap.userData = userObjet
        // snap.firstName = snap.profileName.split(" ", 1);
        // snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);

      });
    });
  }

  onItemChange($event: any): void {
  }

  handleError(error: any) {
    console.log(error)
  }
  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight - 50; // Reducing height by 10%
  }
  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
      console.log
    }
  }
  getEnvironmentName(environment) {
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    this.envFileName = env
    return env != undefined ? env['displayName'] : 'default';
  }

  environmentPopup(environment) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0, 9)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    if (environment == 'default') {
      this.evnFiledefault == 'default'
    } else {
      this.evnFileListFileName['envFileName'] = environment
    }

  }



  getEnvironmentProfile(envFileName) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + envFileName)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === envFileName)
    this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
    this.router.navigate(['yologram-social/yologram-env-profile', { 'evnFile': this.evnFileListFileName.displayName }])
  }

  setBrandDetails(garmentId: string) {
    // if (this.garmentsCollection.length > 0) {
    //   let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    //   if (grandmentObject && grandmentObject['Name'] != undefined) {
    //     let brandId = grandmentObject["BrandID"];
    //     this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
    //     this.brandDetails['boardName'] = 'Not Link to Board';
    //     let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
    //     if (brandBoardObject != undefined) {
    //       let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
    //       let boardDetails = this.boards.find((b: any) => b.id == boardId);
    //       this.brandDetails['boardName'] = boardDetails['name'];
    //     }
    //   }
    //   let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
    //   this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
    //   this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
    //     return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
    //       this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
    //       this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
    //       this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0

    //   }).slice(0, 9);
    // }
    this.openBrandPoup(garmentId)

  }

  // getSnapShotListByUser(snapshot: any) {
  //   let userId = snapshot;
  //   this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
  //   this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
  //   this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
  // }


  getBrandStyle(brandName: any, gender: any) {
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.router.navigate(['yologram-social/yologram-brand-style', { 'brandId': brandName, 'Gender': gender }])
  }

  getGarmentsDetails(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);

      if (grandmentObject && grandmentObject['Name'] != undefined) {
        name = grandmentObject['Name'];
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
    return garmentTitle;
  }

  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }


  getSnapShotListByUser(snapshot: any) {
    let userId = this.id
    let userObjectForProfile = this.SnapShotFileDetails.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    this.router.navigate(['/edit-user', { 'id': snapshot, 'pagetab': 'story', 'flag': 'delete' }])
  }

  getProfileForUSerFeed(snapshot) {
    let userId = this.id
    let userObjectForProfile = this.SnapShotFileDetails.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    this.router.navigate(['/edit-user', { 'id': snapshot.UserID, 'flag': 'delete', 'pagetab': 'user-story', 'storyId': snapshot.StoryID }])
  }

  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }

  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }


  showBrand(brand: any) {
    this.selectedBrand = brand;
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });

  }
  selectedBoard($event: any) {
    this.boardId = $event.target.value;
  }

  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    this.loading = true;
    let brandId: any;
    let boardId: any;

    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }

    }

    if (boardId == undefined || brandId == '') {
      alert("This brand is not linked with Pinterest board.")
      this.displayProgressSpinner = false;
      this.loading = false
      this.isLoading = false;
      return true;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);
    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };
      this.displayProgressSpinner = true;
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.loading = false
        // this.openModel();
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {

        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }
      this.displayProgressSpinner = false;
      alert("Pin published successfully.");
      this.isLoading = false;
      this.loading = false
      return true;
    });

    return false;
  }



  // openModel() {
  //   this.isLoading = false;
  //   const dialogRef = this.dialog.open(DialogContentExampleDialog);
  //   dialogRef.componentInstance.pinImgSrc = this.pinImg;
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.loading = false
  //     this.isLoading_2 = false;
  //   });
  // }

  linkBrandToBoard() {

    if (this.boardId == "" || this.brandId == '') {
      return false;
    }
    let brandBoardMappingObject = {
      'brandId': this.brandId,
      'boardId': this.boardId
    }
    this.firestore.collection('brand-board').add(brandBoardMappingObject)
    return true;
  }

  deleteSnapshotFile(id: any) {
    var confirmation = confirm('Are you sure to delete this Post ?');
    if (!confirmation) return;
    this.isLoading = true;
    this.firestore.collection("SnapshotDetails").doc(id).delete().then(() => {
      let filepath = "Snapshots/" + id
      this.storage.ref(filepath).delete();
      this.getSnapShotFiles();
      this.isLoading = false;
    }).catch((error) => {
    });;
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/edit-news' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("yologram-social/menu-page");
        }
      });
    });
  }
  ngAfterViewChecked(): void {
    if (this.storyId != undefined) {
      document.getElementById(this.storyId)
      this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
    }
  }


  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brand.id }])
  }

  getBrandProfile(brand: any, board: any) {
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand);
    let brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0

    })
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.dataSharingService.sendBrandGarments(brandGarmentIds);
    this.dataSharingService.sendBrandBrandBoard(board)
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand, 'bname': board }])
  }

  getBrandProfilePopup(brand: any, board: any) {
    this.dataSharingService.sendBrandGarments(this.brandhShapShotsList);
    this.dataSharingService.sendBrandBrandBoard(board)
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand.id }])
  }

  getBrandProfilePage(brand: any, board: any) {
    this.getBrandProfile(brand, board)
  }

  getProfileByAvator(snapshot: any) {
    let userId = snapshot.UserID;
    let filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
    this.dataSharingService.sendUserSnapShots(filterSnapShotFileDetails);
    this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId, 'avatarID': snapshot.AvatarID }])
  }

  getFeed(brand: any, brandName: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID }])
  }

  getuserfeed(uid) {
    this.SnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == uid)
  }

  getuserfeedUPopoUp(uid) {
    this.SnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == uid)
  }


  getClothFeed(brand: any, brandName: any) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brandName.BrandID, 'storyId': brand.StoryID }])
  }


  getSnapShotListByUserPopup(snapshot: any) {
    let userId = snapshot.UserID;
    this.selectedId = userId
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == snapshot.UserID)
    this.profileName = userObjet
    this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId).slice(0, 3);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
      this.userFiledetails = filelist
    });
    this.openUserPopup(snapshot)
  }

  openBrandPoup(garmentId: any) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const modalRef = this.modal.open(BrandPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.routeFlageBrandPage = '/yologram-social/yologram-brand-stories';
    modalRef.componentInstance.garmentId = garmentId;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  openUserPopup(item: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.userData = item.userData;
    modalRef.componentInstance.rootFlag = '/edit-user';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  openAvatrPopup(item: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.AvatarID = item.AvatarID;
    modalRef.componentInstance.userData = item.userData;
    modalRef.componentInstance.userProfile = 'userProfile';
    modalRef.componentInstance.rootFlag = 'yologram-social/yologram-user-profile-feed';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openGarmentPopup(fileType: any, key: any) {
    // const modalRef = this.modal.open(GarmentPopupComponent, {
    //   modalDialogClass: "model-garmentPopupClass"
    // });
    // modalRef.componentInstance.id = 10;
    // modalRef.componentInstance.key = fileType;
    // modalRef.componentInstance.garmentId = key;
    // modalRef.componentInstance.routeFlageEvenGarment = '/edit-garment';
    // modalRef.result.then((result) => {
    //   console.log(result);
    // }).catch((error) => {
    //   console.log(error);
    // });
    this.germentCollapseFlag = true
    this.garmentId = key
    this.filetype = fileType
    this.setClothDetails(key)
  }


  newEnvironmentPopup(environment) {
    this.environmentFileName = environment.Environment
    let reqInputObject = { id: environment, pageNumber: 1 }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
      let environmentSnapAllfileAll = evn.object;
      this.dataSharingService.sendUserEnvSnapShots(environmentSnapAllfileAll);
    })
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.filterSnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0, 3)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
    let evnUrl = evenListName + environment
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.openModelevn(evnUrl, environment)

  }
  openModelevn(skyBoXsrc: any, evnName: any) {
    let evnEncodedurl = encodeURI(skyBoXsrc).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl
    const dialogRef = this.dialog.open(PopupThreeDComponent, {
      panelClass: 'dailog-padding-css'
    });
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = this.twoDprivew
    this.evnName = this.evnFileListFileName['envFileName']
    this.evsdiplaynameALL = this.evnFileListFileName['displayName']
    dialogRef.componentInstance.evsdiplaynameALL = this.evsdiplaynameALL
    dialogRef.componentInstance.evsname = this.evnName
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.componentInstance.hideFlag = this.hideFlag;
    dialogRef.componentInstance.routeFlageEven = 'yologram-social/yologram-env-profile'
    dialogRef.componentInstance.environmentFileName = evnName;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getSnapShotListAvatarPopup(snapshot: any) {

    let userId = snapshot.UserID;
    this.userid = userId
    let avatarId = snapshot.AvatarID
    this.selectedAvtar = avatarId

    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    this.apiHttpService.get(url).subscribe((data: any) => {
      this.profileDetails = data
    })
    this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 9);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
  }


  getProfileByAvatorpage(snapshot: any) {
    let snapdetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot)
    let uid = this.userid
    this.dataSharingService.sendUserSnapShots(snapdetails);
    this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': uid, 'avatarID': snapshot }])
  }



  getClothProfile(brand: any,) {
    let clothAllDetails = this.clothDetails
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    }).slice(0, 9);
    let snanpDetails = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snanpDetails);
    this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
    this.dataSharingService.sendClothDetails(clothAllDetails)
    this.router.navigate(['yologram-social/yologram-garment-edit', { 'brandId': brand }])
  }



  getgarmentStyle(cloths: any) {
    let editgarment = 'edit'
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'edit': editgarment }])
  }


  getStoryCloth(brandIds) {
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(brandIds)
    let brandid = brandIds.BrandID
    this.router.navigate(['yologram-social/yologram-garment-edit', { 'brandId': brandid, }])
  }



  getBrandProfileByGarment(brand: any, board: any) {
    this.brandGarmentIds = []
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand);
    this.brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.brandGarmentIds.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandGarmentIds.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandGarmentIds.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandGarmentIds.indexOf(snapshot.UpperwearID.trim()) >= 0
    })
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.dataSharingService.sendBrandGarments(this.brandGarmentIds);
    this.dataSharingService.sendBrandBrandBoard(board)
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand }])
  }

  getgarmentfeed(brandName: any, cloths: any) {
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }

  handleGarmentPanel(eventData: any) {
    console.log('Received Data:', eventData);
    // You can access the emitted parameters like this:
    const { snapshot, typerId, garmentId, type, event } = eventData;

    this.openGarmentNav(snapshot, typerId, garmentId, type, event)

    // Now you can handle the parameters as needed

  }




  async openGarmentNav(snapshot: any, typerId: any, garmentId: any, type: any, event: any) {
    event.stopPropagation();
    // this.showTempPanel()
    this.germentCollapseFlag = true;
    this.closeDiv = true;
    this.wholeDiv = false;
    this.selecteGarmentId = garmentId;
    this.typeOfGarment = type;
    this.garmentTypeID = typerId;
    this.garmentData = [];
    this.selectedGarmentName = this.getGarmentName(garmentId);
    this.brandUrl = this.getBrandImageByGarmetnID(garmentId);
    this.realType = this.getGarmentrealtype(garmentId);

    if (this.garmentDataCache[garmentId]) {
      this.garmentData = this.garmentDataCache[garmentId];
      console.log("when already cache ", this.garmentData);
      const index = this.garmentDataCacheOrder.indexOf(garmentId);
      if (index !== -1) {
        this.garmentDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
    } else {
      this.garmentData = await this.garmentPanelDataFunction(garmentId, typerId);
      if (this.garmentDataCacheOrder.length >= 5) {
        const oldestCacheId = this.garmentDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.garmentDataCache[oldestCacheId];
      }
      this.garmentDataCache[garmentId] = this.garmentData;
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
      let filePathNewTemp = this.garmentData.userFiles.find((file) => file.link == this.selecteGarmentId && file.fileType == 'Garment360Cache');
      console.log("filePathNewTemp.....................................................filePathNewTemp", filePathNewTemp)

      this.clothDetails = this.garmentData.garment
      if (!filePathNewTemp) {
        console.error("filePathNewTemp is undefined or null");
        // await this.fileUrlUpadate(this.clothDetails);
      } else {
        if (filePathNewTemp.filepath) {
          console.log("filePathNewTemp.filepath:", filePathNewTemp.filepath);
          const storageRef = this.storage.ref(filePathNewTemp.filepath);
          try {
            const downloadUrl = await storageRef.getDownloadURL().toPromise();
            console.log("Download URL:", downloadUrl);
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.error("File not found:", filePathNewTemp.filepath);
              await this.fileUrlUpadate(this.clothDetails);
            } else {
              console.error("Error fetching download URL:", error);
            }
          }
        } else {
          console.error("filePathNewTemp.filepath is undefined");
          await this.fileUrlUpadate(this.clothDetails);
        }
      }


    }
  }
  async garmentPanelDataFunction(garmentId, typeWear) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId, fileType: typeWear }).toPromise();
  }
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    // const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed');
    const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${newPath}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  clickOnPanel(event) {
    event.stopPropagation()
  }

  async fileUrlUpadate(data) {
    console.log(data)
    let garment = data
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      garment.BrandID +
      '_' +
      garment.Gender +
      '%2F' +
      zipNmae;
    let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    const url = garmentLink;
    const cloth = garment.id;
    console.log("url..............", url)

    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });

  }
  closePanel() {
    this.wholeDiv = true;
    this.closeDiv = false;
    this.germentCollapseFlag = false;
  }




  setGarmentCollection($event) {
    this.editGarmentDetails.CollectionName = $event.target.value

    if ($event.target.value == '') {
      this.editGarmentDetails.CollectionName = null
    }
  }
  setGarmentName($event) {
    this.editGarmentDetails.Name = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.CollectionName = null
      alert("Please Enter The Garment Name")
      return;
    }
  }
  setGarmentSKU($event) {
    this.editGarmentDetails.SKU = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.SKU = null
    }

  }
  setGarmentDetails($event) {
    this.editGarmentDetails.Details = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.Details = null
    }
  }
  setGarmentThumbCenterURL($event) {
    this.editGarmentDetails.ThumbCenterURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbCenterURL = null
    }
  }
  setGarmentThumbLeftURL($event) {
    this.editGarmentDetails.ThumbLeftURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbLeftURL = null
    }
  }
  setGarmentThumbRightURL($event) {
    this.editGarmentDetails.ThumbRightURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbRightURL = null
    }
  }

  garmentDetailsEdit(editPage) {
    this.clothDetails = editPage
    this.editGarmentDetails = editPage;
    let findLinkId = this.collectionDetails.find((data) => data.id == this.editGarmentDetails.CollectionName)
    let filterdata = this.collectionData.find((data) => data.id == findLinkId.GarmentCollectionID)
    this.linkId = findLinkId?.id
    this.filterdataGarmentCollection = filterdata
    this.selectedOption = filterdata && filterdata.id != undefined ? filterdata.id : 'None';
  }

  selectCollection($event) {
    this.collectionObject = {}
    this.collectionName = $event.target.value;
    if (this.collectionName != '')
      this.collectionObject = this.collectionData.find((collection) => collection.id === this.collectionName)
  }

  filterCollection() {
    let brandId = this.clothDetails?.BrandID
    let Gender = this.clothDetails?.Gender
    let filteredCollection = this.collectionData.filter((collection) =>
      collection.BrandId === brandId && (collection.Gender === Gender || collection.Gender == 'B'))
    this.collectionFilterData = filteredCollection.filter((data) => data.status == "A")
  }


  updateGarmentDetailsEdit() {
    if (this.collectionName != '' && this.collectionName != undefined) {
      this.saveCollection(this.collectionObject)
      let seletctCollection = this.collectionDetails.find((data) => data.GarmentCollectionID == this.collectionObject.id)
      this.editGarmentDetails.CollectionName = seletctCollection.id
    }
    else {
      this.editGarmentDetails.CollectionName = null;
    }
    let object = {
      CollectionName: this.editGarmentDetails.CollectionName,
      SKU: this.editGarmentDetails.SKU,
      Details: this.editGarmentDetails.Details,
      ThumbCenterURL: this.editGarmentDetails.ThumbCenterURL,
      ThumbLeftURL: this.editGarmentDetails.ThumbLeftURL,
      ThumbRightURL: this.editGarmentDetails.ThumbRightURL,
      Name: this.editGarmentDetails.Name
    };
    this.firestore.collection('garments').doc(this.editGarmentDetails["id"]).update(object);

    this.firestore.collection("garment-collection-items").doc(this.linkId).delete().then(() => {
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }



  saveCollection(colection) {
    let seletctCollection = this.collectionDetails.find((data) => data.GarmentCollectionID == colection.id)
    if (seletctCollection == undefined) {
      let garmentId = this.clothDetails.id
      let collectionObject = {
        GarmentCollectionID: colection.id,
        GarmentID: garmentId
      }
      this.firestore.collection('garment-collection-items').add(collectionObject);
    }
  }



  // files: File[] = [];

  start3DFileUpload($event: any) {
    // if (this.envName != '' || this.envName != undefined) { return }
    this.clothId = this.clothDetails.id
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.')[1];
    let uploadCustomerFilename = this.envName + "." + ext
    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;

    const lastIndex = this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ? this.uploadFileName.slice(0, lastIndex) : this.uploadFileName;

    const filename = `${this.file}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename: this.envName != '' ? this.envName : fileNameWithoutExtension,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload3D',
      userId: this.userid,
      link: this.clothId,
    }
    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(url => {
        uploadObject.fileurl = url
        // console.log("Object adding ",uploadObject);
        // this.db.collection('userfiles').add(uploadObject).then((docRef) => {
        //   console.log("File uploaded", docRef);
        // });
        this.firestore.collection('userfiles').add(uploadObject);
      });
    })
  }

  setEnvName($event) {
    this.hideName = true
    this.envName = $event.target.value;
    console.log("FileName", this.envName)
  }

  clear() {
    this.percentage = new Observable<undefined>();
    this.envName = ""
    this.uploadFileName = ""
    if (this.percentage) {
      this.envName = ''
    }
  }

  async getThumbnailForVideo(videoUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.style.display = 'none';
      canvas.style.display = 'none';

      // Set crossOrigin attribute to handle cross-origin video sources
      video.crossOrigin = 'anonymous';

      document.body.appendChild(video);
      document.body.appendChild(canvas);

      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = video.duration * 0.25;
      });

      video.addEventListener('seeked', () => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          // Draw play button watermark
          const playButtonSize = Math.min(video.videoWidth, video.videoHeight) / 4;
          const centerX = video.videoWidth / 2;
          const centerY = video.videoHeight / 2;

          // Draw circle
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.beginPath();
          ctx.arc(centerX, centerY, playButtonSize / 2, 0, 2 * Math.PI);
          ctx.fill();

          // Draw triangle (play icon)
          ctx.fillStyle = 'white';
          ctx.beginPath();
          ctx.moveTo(centerX - playButtonSize / 6, centerY - playButtonSize / 4);
          ctx.lineTo(centerX - playButtonSize / 6, centerY + playButtonSize / 4);
          ctx.lineTo(centerX + playButtonSize / 3, centerY);
          ctx.closePath();
          ctx.fill();

          const imageUrl = canvas.toDataURL('image/png');
          document.body.removeChild(video);
          document.body.removeChild(canvas);
          resolve(imageUrl);
        } else {

          document.body.removeChild(video);
          document.body.removeChild(canvas);

          reject(new Error('Unable to get canvas context'));
        }
      });

      video.addEventListener('error', (e) => {
        document.body.removeChild(video);
        document.body.removeChild(canvas);
        reject(e);
      });
      video.src = videoUrl;
    });
  }
  async start2DFileUpload(event: any) {
    this.clothId = this.clothDetails.id
    const uploadFile = event.target.files[0];
    if (!uploadFile) return;

    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.').pop().toLowerCase();
    const lastIndex = this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ? this.uploadFileName.slice(0, lastIndex) : this.uploadFileName;
    console.log('fileNameWithoutExtension', fileNameWithoutExtension);

    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;
    const ref = this.storage.ref(pathEvn);


    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename: this.envName !== '' ? this.envName : fileNameWithoutExtension,
      fileurl: '',
      fileurlMini: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload2D',
      userId: this.userid,
      link: this.clothId,
    };

    try {
      // Check if file already exists
      const existingFiles = await this.firestore.collection('userfiles', ref => ref.where('filepath', '==', pathEvn)).get().toPromise();
      if (!existingFiles.empty) {
        alert('File already exists. Upload aborted.')
        console.log('File already exists. Upload aborted.');
        return;
      }
    } catch (error) {
      console.error('Error checking existing files:', error);
    }
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges()

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    let fileType;

    if (imageExtensions.includes(ext)) {
      console.log('File is an image:', this.uploadFileName);
      fileType = 'image';
    } else if (videoExtensions.includes(ext)) {
      console.log('File is a video:', this.uploadFileName);
      fileType = 'video';
    } else {
      console.log('File type is unknown:', this.uploadFileName);
    }

    this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        const downloadURLTest = ref.getDownloadURL().toPromise();

        uploadObject.fileurl = await downloadURLTest;

        if (fileType === 'video') {
          try {
            const thumbnailUrl = await this.getThumbnailForVideo(uploadObject.fileurl);
            const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_thumbnail.png");
            const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
            const thumbRef = this.storage.ref(compressedFilePath);
            await thumbRef.putString(thumbnailUrl, 'data_url');
            uploadObject.fileurlMini = await thumbRef.getDownloadURL().toPromise();

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              const docRef = await this.firestore.collection('userfiles').add(uploadObject);
              await docRef.update({ '2DType': 'Video' });
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error generating or uploading thumbnail:', error);
          }
        } else {
          const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
          const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
          let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
          try {
            const response = await this.http.post(functionUrl, {
              imageUrl: uploadObject.fileurl,
              path: compressedFilePath
            }).toPromise();
            uploadObject.fileurlMini = response['fileurlMini'];
            console.log("uploadObject.fileurlMini", uploadObject.fileurlMini);

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              await this.firestore.collection('userfiles').add(uploadObject);
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error compressing file:', error);
          }
        }
      })
    ).subscribe();
  }


  handleModalOpen(s) {
    this.garmentDetailsEdit(this.clothDetails)
    const modalElement = document.getElementById('pageModalLabel');
    const modal = new Modal(modalElement);
    modal.show();
  }


  handletwoduploadOpen(s) {
    const modalElement = document.getElementById('upload2D');
    const modal = new Modal(modalElement);
    modal.show();
  }

  handlethreeuploadOpen(s) {
    const modalElement = document.getElementById('upload3D');
    const modal = new Modal(modalElement);
    modal.show();
  }


  handleStorySearch(eventData: any) {
    // Log the event object
    const { snapshot, event } = eventData;
    event.stopPropagation();

    console.log("snapshot", snapshot)

    console.log('Search Event:', event);

    // Change state values and log them

    this.showSearchPanel = true;

    console.log('showSearchPanel:', this.showSearchPanel);



    this.germentCollapseFlag = false;
    console.log('germentCollapseFlag:', this.germentCollapseFlag);

    this.closeDiv = true;
    console.log('closeDiv:', this.closeDiv);

    this.wholeDiv = false;
    console.log('wholeDiv:', this.wholeDiv);
    this.selectStorySearch(snapshot)
  }



  handlePlusClick() {
    const lastField = this.searchFields[this.searchFields.length - 1];

    // Only add a new set if the last one has a valid selected option
    if (lastField.selectedOption && (lastField.selectedOption === 'User' || lastField.selectedOption === 'Garment' || lastField.selectedOption === 'Brand'

      || lastField.selectedOption === 'Avatar' || lastField.selectedOption === 'Env'
    )) {
      this.searchFields.push({ selectedOption: '', inputValue: '', isDropdownOpen: false });
    } else {
      console.log('Please select a valid option before adding more.');
    }

    this.lastfeild = lastField.selectedOption


  }


  handleOptionChange(event: any, index: number) {
    this.snapInitCall = false
    if (this.singleSnap) {
      this.singleSnap = null
      return
    }
    // Update the selected option in the array for the specific dropdown
    this.searchFields[index].selectedOption = event.target.value;
    // this.selectedOption = event.target.value
    if (this.selectedOption === 'User') {
      this.User = this.selectedOption;
    } else if (this.selectedOption === 'Garment') {
      this.garment = this.selectedOption;
    } else if (this.selectedOption === 'Brand') {
      this.brand = this.selectedOption;
    } else if (this.selectedOption === 'Avatar') {
      this.avatar = this.selectedOption;
    }
    else if (this.selectedOption === 'Env') {
      this.env = this.selectedOption;
    }
  }

  handleInputChange(event: any, index: number) {
    console.log("index", index)
    this.snapInitCall = false
    if (this.singleSnap) {
      this.singleSnap = null
      return
    }
    // Update the input value in the array for the specific input
    this.searchFields[index].inputValue = event.target.value;

    this.inputValue = event.target.value;

    this.searchFields.forEach((field, index) => {
      if (field.selectedOption === 'User') {
        this.userOption = field.inputValue;
      } else if (field.selectedOption === 'Garment') {
        this.garmentOption = field.inputValue;
        if (index < this.searchTerms.length) {
          this.searchTerms[index] = this.garmentOption.toLowerCase();
        } else {
          this.searchTerms.push(this.garmentOption.toLowerCase());
        }
      } else if (field.selectedOption === 'Brand') {
        this.brandOption = field.inputValue;
      } else if (field.selectedOption === 'Avatar') {
        this.avatarOption = field.inputValue;
      } else if (field.selectedOption === 'Env') {
        this.envOption = field.inputValue;
      }
    });

    this.searchTerms = this.searchTerms || [];
    if (this.selectedOption === 'Garment') {
      // If the index already exists in searchTerms, replace it; otherwise, push
      if (index < this.searchTerms.length) {
        this.searchTerms[index] = this.garmentOption.toLowerCase();
      } else {
        this.searchTerms.push(this.garmentOption.toLowerCase());
      }
    }

    console.log("Updated searchTerms:", this.searchTerms);

  }




  async search() {

    this.userSnafilter = [];
    this.garmentMap = {}; // Reset garmentMap for the new search
    let commonGarmentIDs = [];

    this.searchFields.forEach((field, index) => {
      if (field.selectedOption === 'User') {
        this.userOption = field.inputValue;
      } else if (field.selectedOption === 'Garment') {
        this.garmentOption = field.inputValue;
        
        // Convert the garment option to lowercase for comparison
        const garmentValueLower = this.garmentOption.toLowerCase();
    
        // Check if the value already exists in the searchTerms array
        if (index < this.searchTerms.length) {
          this.searchTerms[index] = garmentValueLower;
        } else if (!this.searchTerms.includes(garmentValueLower)) {
          this.searchTerms.push(garmentValueLower);
        }
      } else if (field.selectedOption === 'Brand') {
        this.brandOption = field.inputValue;
      } else if (field.selectedOption === 'Avatar') {
        this.avatarOption = field.inputValue;
      } else if (field.selectedOption === 'Env') {
        this.envOption = field.inputValue;
      }
    });
    
    

    // if (this.selectedOption === 'User') {
    //   this.userOption = this.inputValue;
    // } else if (this.selectedOption === 'Garment') {
    //   this.garmentOption = this.inputValue;
    // } else if (this.selectedOption === 'Brand') {
    //   this.brandOption = this.inputValue;
    // }
    // else if (this.selectedOption === 'Avatar') {
    //   this.avatarOption = this.inputValue;
    // }
    // else if (this.selectedOption === 'Env') {
    //   this.envOption = this.inputValue;
    // }

    console.log('Search terms:', this.searchTerms);
    console.log('  this.selectedOption:', this.selectedOption);



    console.log('Search terms:', this.searchTerms);
    console.log("Updated searchTerms:", this.searchFields);


    // Find garments based on input terms
    let garmentIDs = [];
    let garmentIDsPerTerm = [];
    if (this.searchTerms.length == 1) {
      console.log('Search terms if:', this.searchTerms);
      for (let term of this.searchTerms) {
        let filteredGarments = this.garmentsCollection.filter((garment) =>
          garment?.Name.toLowerCase().includes(term)
        );

        console.log("filtered by name if:", filteredGarments)
        let termGarmentIDs = filteredGarments.map((garment) => garment.id);
        garmentIDsPerTerm.push(termGarmentIDs);
      }

      // Find the intersection of IDs across all search terms
      if (garmentIDsPerTerm.length > 0) {
        commonGarmentIDs = garmentIDsPerTerm.reduce((acc, ids) =>
          acc.filter(id => ids.includes(id))
        );
      }
    } else if (this.searchTerms.length > 1) {
      for (let term of this.searchTerms) {
        let filteredGarments = this.garmentsCollection.filter((garment) =>
          garment?.Name.toLowerCase().includes(term)
        );
        filteredGarments.forEach((garment) => {
          garmentIDs.push(garment.id);
          // Categorize garment IDs based on their type
          if (!this.garmentMap[garment.GarmentType]) {
            this.garmentMap[garment.GarmentType] = [];
          }
          this.garmentMap[garment.GarmentType].push(garment.id);
        });
      }
    }



    console.log('Garment IDs by type:', this.garmentMap);

    // Find brands based on input
    let brands = this.brandsCollection.filter((b) =>
      b.BrandName?.toLowerCase().includes(this.brandOption?.toLowerCase())
    );

    let brandIDs = brands.map((b) => b.id);
    // Find garments for the selected brand
    let brandGarments = this.garmentsCollection.filter((g) =>
      brandIDs.includes(g.BrandID)
    );
    let brandGarmentIDs = brandGarments.map((g) => g.id);





    // Find users based on input
    let users = this.userData.filter((u) =>
      u?.DisplayName.toLowerCase().includes(this.userOption?.toLowerCase())
    );


    let userIDs = users.map((u) => u.UserID);

    // Find avatar based on input
    let avatar = this.userSnap.filter((u) =>
      u?.AvatarName.toLowerCase().includes(this.avatarOption?.toLowerCase())
    );

    console.log("avatar", avatar)

    let avatarName = avatar.map((u) => u.AvatarName);

    console.log("avatarName", avatarName)


    let defaultValue = 'default'
    let envFileNames


    if (defaultValue.toLowerCase().includes(this.envOption?.toLowerCase())) {
      envFileNames = [defaultValue];
    } else {
      let env = this.evnFileList.filter((u) =>
        u?.displayName.toLowerCase().includes(this.envOption?.toLowerCase())
      );
      envFileNames = env.map((u) => u.storageURL.replace('Environments/', ''));
    }






    console.log('Env File Names:', envFileNames);
    console.log('User IDs:', userIDs);
    console.log('Garment IDs:', garmentIDs);
    console.log('Brand Garment IDs:', brandGarmentIDs);

    this.snapInitCall = true;
    if (this.singleSnap) {
      this.singleSearch(this.singleSnap)
      this.singleSnap = null
      return
    }

    // Check if the garmentMap has only one garment type
    const isSingleGarmentType = Object.keys(this.garmentMap).length === 1;

    // Filter userSnap based on all inputs



    this.userSnafilter = this.userSnap.filter((snap) => {
      // Check User filter
      const matchesUser = userIDs.length === 0 || userIDs.includes(snap.UserID);
      const matchesAvatar = avatarName.length === 0 || avatarName.includes(snap.AvatarName);


      const matchEnvFile = envFileNames.length === 0 || envFileNames.includes(snap.Environment);

      // Check specific garment types (Headwear, Upperwear, Bottomwear, Footwear)
      const matchesUpperwear = isSingleGarmentType && this.garmentMap['upperwear']
        ? true // If only one garment type is searched, allow all snaps for this type
        : this.garmentMap['upperwear']
          ? this.garmentMap['upperwear'].includes(snap.UpperwearID)
          : true;

      const matchesBottomwear = isSingleGarmentType && this.garmentMap['bottomwear']
        ? true // If only one garment type is searched, allow all snaps for this type
        : this.garmentMap['bottomwear']
          ? this.garmentMap['bottomwear'].includes(snap.BottomwearID)
          : true;

      const matchesHeadwear = isSingleGarmentType && this.garmentMap['headgear']
        ? true // If only one garment type is searched, allow all snaps for this type
        : this.garmentMap['headgear']
          ? this.garmentMap['headgear'].includes(snap.HeadwearID)
          : true;

      const matchesFootwear = isSingleGarmentType && this.garmentMap['footwear']
        ? true // If only one garment type is searched, allow all snaps for this type
        : this.garmentMap['footwear']
          ? this.garmentMap['footwear'].includes(snap.FootwearID)
          : true;

      // Check Brand Garment filter: Ensure that at least one of the garment IDs matches a brand garment

      const matchesBrandGarment =
        brandGarmentIDs.length === 0 ||
        brandGarmentIDs.includes(snap.HeadwearID) ||
        brandGarmentIDs.includes(snap.UpperwearID) ||
        brandGarmentIDs.includes(snap.BottomwearID) ||
        brandGarmentIDs.includes(snap.FootwearID);

      const matchesGarment =
        commonGarmentIDs.length === 0 ||
        commonGarmentIDs.includes(snap.HeadwearID) ||
        commonGarmentIDs.includes(snap.UpperwearID) ||
        commonGarmentIDs.includes(snap.BottomwearID) ||
        commonGarmentIDs.includes(snap.FootwearID);


      // Return true only if the snap matches all non-empty filters
      return (
        matchesUser &&
        matchesGarment &&
        matchesUpperwear &&
        matchesBottomwear &&
        matchesHeadwear &&
        matchesFootwear &&
        matchEnvFile &&
        matchesBrandGarment
        && matchesAvatar
      );
    });

    let removeSnapShot = []
    //this.userSnafilter = 
    this.userSnafilter.filter(async (snap) => {
      let filterKeyWordFlag = {};
      await this.searchTerms.forEach((key) => {
        filterKeyWordFlag[key] = false;
        if (!filterKeyWordFlag[key]) filterKeyWordFlag[key] = this.garmentsCollection.find((x => x.id === snap.HeadwearID))?.Name.toLowerCase().includes(key)
        if (!filterKeyWordFlag[key]) filterKeyWordFlag[key] = this.garmentsCollection.find((x => x.id === snap.UpperwearID))?.Name.toLowerCase().includes(key)
        if (!filterKeyWordFlag[key]) filterKeyWordFlag[key] = this.garmentsCollection.find((x => x.id === snap.BottomwearID))?.Name.toLowerCase().includes(key)
        if (!filterKeyWordFlag[key]) filterKeyWordFlag[key] = this.garmentsCollection.find((x => x.id === snap.FootwearID))?.Name.toLowerCase().includes(key)
      })
      for (var i = 0, len = this.searchTerms.length; i < len; i++) {
        console.log(filterKeyWordFlag[this.searchTerms[i]])
        if (filterKeyWordFlag[this.searchTerms[i]] == undefined) {
          console.log('Loop is going to break.');
          removeSnapShot.push(snap.id)
          break;
        }
      }
      if (this.searchTerms.length > 1) {
        this.userSnafilter = await this.userSnafilter.filter(
          (snap) => !removeSnapShot.includes(snap.id)
        );


        this.snapFiltered = this.userSnafilter.slice(0, 12);
        this.paginatedSnapshots = this.snapFiltered;

        console.log("this.snapFiltered", this.snapFiltered)
        console.log(" this.paginatedSnapshots", this.paginatedSnapshots)

      }

      this.storyFilterFlag = false
      if (this.snapFiltered.length == 0) {
        this.storyFilterFlag = true
      }
    })

    this.snapFiltered = this.userSnafilter.slice(0, 12);
    setTimeout(() => {

      this.paginatedSnapshots = this.snapFiltered;
      console.log("Paginated snapshots updated after delay:", this.paginatedSnapshots);
    }, 500);
    console.log("this.snapFiltered", this.snapFiltered)
    console.log(" this.paginatedSnapshots", this.paginatedSnapshots)

    // Initialize snaps if no results are found
    if (this.userSnafilter.length === 0) {
      this.snapInit();
    }

    console.log('Filtered data with three inputs:', this.userSnafilter);
  }
  // Other methods...

  handleSearchClick() {
    console.log('Search button clicked with input:', this.inputValue);
    this.search()
    // Add your logic here
  }

  handleSearchClear() {
    this.inputValue = '';
    this.userSnafilter = [];
    this.snapFiltered = [];
    this.searchTerms = []
    this.Brand = null;
    this.User = null;
    this.garment = null;
    this.selectedOption = null
    this.userOption = null
    this.garmentOption = null
    this.brandOption = null
    this.searchFields = [{ selectedOption: '', inputValue: '', isDropdownOpen: false }]; // Keep at least one dropdown with proper properties
    console.log('Search fields cleared, retaining one dropdown.');
    this.snapInit()
  }




  // removeField(field: any, index: number) {
  //   console.log(index)
  //   this.searchFields.splice(index, 1);
  //   console.log('field:', field.selectedOption);
  //   console.log('field:', field);
  //   console.log('first Time removal, searchTerms:', this.searchTerms);
  //   if (field.selectedOption === 'User') {
  //     this.userOption = null;
  //   } else if (field.selectedOption === 'Garment') {
  //     // this.garmentOption = null;
  //     console.log('Before removal, searchTerms:', this.searchTerms);
  //     const termIndex = this.searchTerms.findIndex(
  //       (term) => term.toLowerCase().trim() === field.inputValue.toLowerCase().trim()
  //     );

  //     if (termIndex > -1) {
  //       this.searchTerms.splice(termIndex, 1);
  //       console.log('After removal, searchTerms:', this.searchTerms);
  //     } else {
  //       console.log('Term not found for removal:', field.inputValue);
  //     }
  //   } else if (field.selectedOption === 'Brand') {
  //     this.brandOption = null;
  //   }
  //   else if (field.selectedOption === 'Avatar') {
  //     this.avatarOption = null;
  //   }
  //   else if (field.selectedOption === 'Env') {
  //     this.envOption = null;
  //   }
  // }

  removeField(field, index) {
    console.log(index);
    this.searchFields.splice(index, 1);
    console.log('field:', field.selectedOption);
    console.log('field:', field);
    console.log('First time removal, searchTerms:', this.searchTerms);
  
    if (field.selectedOption === 'User') {
      this.userOption = null;
    } else if (field.selectedOption === 'Garment') {
      console.log('Before removal, searchTerms:', this.searchTerms);
  
      // Find the index of the term that matches exactly with the field's input value
      const termIndex = this.searchTerms.findIndex(
        (term) => term.toLowerCase().trim() === field.inputValue.toLowerCase().trim()
      );
  
      if (termIndex > -1) {
        // Remove the term from searchTerms array
        this.searchTerms.splice(termIndex, 1);
        console.log('After removal, searchTerms:', this.searchTerms);
      } else {
        console.log('Term not found for removal:', field.inputValue);
      }
    } else if (field.selectedOption === 'Brand') {
      this.brandOption = null;
    } else if (field.selectedOption === 'Avatar') {
      this.avatarOption = null;
    } else if (field.selectedOption === 'Env') {
      this.envOption = null;
    }
  }
  


  snapInit() {
    this.snapInitCall = false

    this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
      if (snapshots?.object && snapshots?.object.length > 0) {
        this.snapshots = snapshots?.object
        this.paginatedSnapshots = this.snapshots.slice(0, 12);
        console.log("this.paginatedSnapshots", this.userSnafilter)
        this.snapInitCall = true
      }
    })
  }

  handleSelectedImage(snap) {
    this.StoryID = snap.StoryID
  }

  // moreButtonForNext() {
  //   this.snapInitCall = false
  //   const itemsPerPage = 12;
  //   this.next += 1;
  //   const startIdx = (this.next - 1) * itemsPerPage;
  //   const endIdx = this.next * itemsPerPage;
  //   const newSnapshots = this.userSnafilter.slice(startIdx, endIdx);
  //   this.endIdx = endIdx
  //   if (newSnapshots.length > 0) {
  //     this.snapFiltered.push(...newSnapshots)
  //     setTimeout(() => {
  //       this.snapInitCall = true
  //       this.paginatedSnapshots.push(...newSnapshots);
  //     }, 200)
  //   } else {

  //   }
  // }


  moreButtonForNext() {
    console.log('Initializing moreButtonForNext...');

    this.snapInitCall = false;
    console.log('snapInitCall set to false');

    const itemsPerPage = 12;
    this.next += 1;
    console.log('Current page (next):', this.next);

    const startIdx = (this.next - 1) * itemsPerPage;
    const endIdx = this.next * itemsPerPage;
    console.log('Start index:', startIdx, 'End index:', endIdx);

    const newSnapshots = this.userSnafilter.slice(startIdx, endIdx);
    console.log('New snapshots to add:', newSnapshots);

    this.endIdx = endIdx;
    console.log('Updated endIdx:', this.endIdx);

    if (newSnapshots.length > 0) {
      this.snapFiltered.push(...newSnapshots);
      console.log('snapFiltered updated:', this.snapFiltered);

      setTimeout(() => {
        this.snapInitCall = true;
        console.log('snapInitCall set to true after timeout');

        this.paginatedSnapshots.push(...newSnapshots);
        console.log('paginatedSnapshots updated:', this.paginatedSnapshots);
      }, 200);
    } else {
      console.log('No new snapshots found, no update made to snapFiltered or paginatedSnapshots.');
    }
  }

  async getAvatartDetails(snapShots) {
    try {
      snapShots.AvatarName = '';

      // Check if UserID and AvatarID are available before proceeding
      if (!snapShots.UserID || !snapShots.AvatarID) {
        console.warn('Missing UserID or AvatarID:', snapShots);
        snapShots.AvatarName = snapShots.AvatarID || 'Unknown Avatar';
        return;
      }

      // Fetch avatar details from the common service
      const documents = await this.commonService.getUserAvatarDetails(snapShots.UserID, snapShots.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        snapShots.AvatarName = firstDocument.AvatarName || snapShots.AvatarID;
      } else {
        snapShots.AvatarName = snapShots.AvatarID;
      }
    } catch (error) {
      console.error('Error in getAvatarDetails:', error);
      throw error; // Re-throw the error or handle it here as needed
    }
  }


  selectStorySearch(snap) {
    let feild = this.searchFields
    this.handleSearchClear()
    let user = this.userData.find((u) => u?.UserID === snap.UserID);
    this.userOption = user?.DisplayName || 'debug';
    let AvatarID = snap.avatarName;
    let BottomwearID = snap.BottomwearID;
    let Environment = snap.Environment;
    let FootwearID = snap.FootwearID;
    let HeadwearID = snap.HeadwearID;
    let UpperwearID = snap.UpperwearID;
    let UserID = snap.DisplayName;

    let AId = snap.AvatarID;

    // Helper function to get the garment name by ID
    const getGarmentName = (garmentID) => {
      let garment = this.garmentsCollection.find((g) => g?.id === garmentID);
      return garment ? garment.Name : garmentID; // Use garment.Name if found, otherwise use the ID
    };

    const getBrandName = (garmentID) => {
      let garment = this.garmentsCollection.find((g) => g?.id === garmentID);
      let brand = this.brandsCollection.find((b) => b?.id === garment.BrandID);
      return brand ? brand.BrandName : garment.BrandID; // Use garment.Name if found, otherwise use the ID
    };


    // Check each variable and add to searchFields if not empty
    if (UserID !== "") {
      this.searchFields.push({
        selectedOption: 'User',
        inputValue: this.userOption,
        isDropdownOpen: false
      });

      this.selectedOption = 'User'
    }
    if (AvatarID !== "") {
      this.searchFields.push({
        selectedOption: 'Avatar',
        inputValue: AvatarID,
        isDropdownOpen: false
      });
      this.avatarOption = AvatarID
    }


    if (Environment !== "") {
      let defaultValue = 'default'
      let envFileNames = ''
      let evenPath = 'Environments/' + Environment
      if (defaultValue == Environment) {
        envFileNames = defaultValue;
      } else {
        let env = this.evnFileList.find((u) =>
          u?.storageURL == evenPath)
        envFileNames = env.displayName
        console.log('envFileNames', envFileNames)
      }
      this.searchFields.push({
        selectedOption: 'Env',
        inputValue: envFileNames,
        isDropdownOpen: false
      });


    }
    if (HeadwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(HeadwearID),
        isDropdownOpen: false
      });
      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(HeadwearID),
        isDropdownOpen: false
      });
    }
    if (UpperwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(UpperwearID),
        isDropdownOpen: false
      });

      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(UpperwearID),
        isDropdownOpen: false
      });
    }
    if (BottomwearID !== "") {
      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(BottomwearID),
        isDropdownOpen: false
      });
      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(BottomwearID),
        isDropdownOpen: false
      });
    }

    if (FootwearID !== "") {

      this.searchFields.push({
        selectedOption: 'Brand',
        inputValue: getBrandName(FootwearID),
        isDropdownOpen: false
      });

      this.searchFields.push({
        selectedOption: 'Garment',
        inputValue: getGarmentName(FootwearID),
        isDropdownOpen: false
      });
    }

    this.removeField(feild, 0)
    // this.search()
    this.singleSnap = snap

  }

  singleSearch(snap) {


    let user = this.userData.find((u) => u?.UserID === snap.UserID);
    this.userOption = user?.DisplayName || 'debug';
    let AvatarID = snap.avatarName;
    let BottomwearID = snap.BottomwearID;
    let Environment = snap.Environment;
    let FootwearID = snap.FootwearID;
    let HeadwearID = snap.HeadwearID;
    let UpperwearID = snap.UpperwearID;
    let UserID = snap.DisplayName;

    let AId = snap.AvatarID;
    let searchFilterStory = this.userSnap.filter((item) => {
      return (
        (!UserID || item.UserID === snap.UserID) &&
        (!AId || item.AvatarID === AId) &&
        (!Environment || item.Environment === Environment) &&
        (!HeadwearID || item.HeadwearID === HeadwearID) &&
        (!UpperwearID || item.UpperwearID === UpperwearID) &&
        (!BottomwearID || item.BottomwearID === BottomwearID) &&
        (!FootwearID || item.FootwearID === FootwearID)
      );
    });


    // Paginate the filtered results
    this.snapFiltered = searchFilterStory.slice(0, 12);
    this.paginatedSnapshots = this.snapFiltered;

    console.log("Filtered Snapshots:", this.snapFiltered);
    console.log("Paginated Snapshots:", this.paginatedSnapshots);
    console.log("Search Fields:", this.searchFields);

  }



  toggleDropdown(index: number) {
    this.searchFields[index].isDropdownOpen = true
  }

}


@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './dialog-content-example-dialog.html',
})
export class DialogContentExampleDialog {
  @Input() pinImgSrc: string | undefined;
  constructor(public dialog: MatDialog) {
  }

}