<div *ngIf="loading" class="loading">&#8230;</div>

<app-yologram-social *ngIf="logInFlag"></app-yologram-social>

<div class="brand-profile-color">
    <div #brandStoriyScrollA [ngClass]="scrollFlag ? 'd-none' : ''"  class="slider-css" (scroll)="selectByScroll()">
        <div class="" *ngFor="let brand of assignBrandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
            <div *ngIf="selectedBrand == brand  ">
                <div [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (!byScroll ? 'd-none' :'')">
                    <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                    </div>
                </div>
            </div>
            <img *ngIf="brand?.logoUrl != undefined" [id]="brand.id" class="m-1"
                style="height:60px !important; width:80px !important;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll && !scrolnewlFlag? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.logoUrl" />
        </div>
    </div>

    <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
        <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
            <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important; background: white;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>
    </div>

    <div class="input-wrapper" *ngIf="brandList">
        <input id="BrandName" type="text" class="form-control custom-input no-rounded"
            (input)="suggest($event)" (click)="blankplaceHolder()">
        <label for="BrandName" class="placeholder-start text-white">{{brandNameSelected}}</label>
    </div> 

    <div class="colocustom-inputr-picker-container d-flex flex-column align-items-center" *ngIf="this.selectedBrand != undefined">
        <!-- Description Box Above -->
        <input type="text" [(ngModel)]="selectedDiscription" (input)="colorDiscription($event)"
            class="form-control mb-2" placeholder="Enter description">

        <!-- Color Pickers Below -->
        <div class="d-flex">
            <!-- Color Picker Input -->
            <input type="color" [(ngModel)]="selectedColor" (input)="updateHexColorFromPicker($event)"
                class="form-control me-2 color-input">

            <!-- Hexadecimal Color Input (Editable) -->
            <input type="text" [(ngModel)]="selectedColor" (input)="updateHexColorFromInput($event)"
                class="form-control" placeholder="#000000">
        </div>
        <button class="btn btn-primary" (click)="saveBrandColor()">Save</button>

    </div>

    <div class="w-100" *ngIf="selectedBrand != undefined && brandColorList">
        <h5 class="m-2">Saved Brand Colors</h5>
        <ul class="list-group">
            <li *ngFor="let color of brandColorList" class="list-group-item d-flex align-items-center justify-content-between">
                <!-- Color Details -->
                <div class="col-8">
                    <strong>{{ color.ColorText }}</strong><br>
                    <span>{{ color.ColorHexCode }}</span>
                </div>
    
                <!-- Color Picker Input and Edit/Delete Buttons -->
                <div class="col-4 d-flex align-items-center">
                    <input type="color" [(ngModel)]="color.ColorHexCode" class="form-control  color-input-selected" read-only>
                    <button class="btn" (click)="openEditModal(color)"><i class="bi bi-pencil-square"></i></button>
                    <button class="btn" (click)="deleteBrandColor(color.id)"><i class="bi bi-trash"></i></button>
                </div>
            </li>
        </ul>
    </div>
    
    <!-- Edit Color Modal -->
    <div class="modal fade" id="editColorModal" tabindex="-1" aria-labelledby="editColorModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editColorModalLabel">Edit Color</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Edit Text and Color -->
                    <div class="mb-3">
                        <label for="colorText" class="form-label">Color Text</label>
                        <input type="text" id="colorText" [(ngModel)]="selectedColorData.ColorText" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label for="colorPicker" class="form-label">Select Color</label>
                        <input type="color" id="colorPicker" [(ngModel)]="selectedColorData.ColorHexCode" class="form-control color-input-selected">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="saveChanges()">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</div>