<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="brand-profile-color" >
    <button class="scroll-button left" (click)="scrollsLeft()">
        &#8249;
    </button>
    <div #brandStoriyScrollA  [ngClass]="scrollFlag ? 'd-none' : ''"
        class="slider-css" (scroll)="selectByScroll()">
        <div class="" *ngFor="let brand of brandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
            <div *ngIf="selectedBrand == brand  ">
                <div
                    [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                    <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                    </div>
                </div>
            </div>
            <img *ngIf="brand?.Brandurl != undefined" [id]="brand.id" class="m-1"
                style="height:60px !important; width:80px !important;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
          
          
        </div>
        <!-- <div *ngIf="loading" class="loading">Loading&#8230;</div> -->
        <button class="scroll-button right" (click)="scrollRight()">
            &#8250;
        </button>
    </div>
    
    <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
        <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
            <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important; background: white;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>
    </div>

    <div class="input-wrapper" *ngIf="brandList">
        <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text"
            class="form-control custom-input no-rounded" (input)="suggest($event)"
            (click)="blankplaceHolder()">
        <label for="BrandName" class="placeholder-start text-white">{{brandNameSelected}}</label>
    </div>
</div>

<div class="col-lg-12 justify-content-center text-center scroll">
    <div class="fixed-container" *ngIf="this.selectedBrandId">
        <div class="justify-content-center text-center  brand-profile-sy">
            <div class="col-lg-12 garmentName">
                <div class="justify-content-center text-center d-flex set-margin">
                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class" (click)="setTab('story','')">
                        <div *ngIf="tab == 'story'" [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                            <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
                        </div>
                        <div *ngIf="tab == 'news-stories'"
                            [ngClass]="tab == 'news-stories' ? 'story-menu-card-gray' : 'story-menu-icon'">
                            <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/story-white.png">
                        </div>
                        <img *ngIf="tab != 'story' && tab != 'news-stories' "
                            [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
                        <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy m-1  story_name">
                            Stories</h6>
                    </h6>
                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                        [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                        <img *ngIf="tab == 'mens-wear'"
                            [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconBlack.png">
                        <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                            [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconWhite.png">
                        <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
                    </h6>
                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                        [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                        <img *ngIf="tab == 'womens-wear'"
                            [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
                        <img (click)="setTab('womens-wear','F')" *ngIf="tab != 'womens-wear'"
                            [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconWhite.png">
                        <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy m-1 w-margin">Womenswear</h6>
                    </h6>
                </div>
            </div>
        </div>
        <div id="mySidenav" class="sidenav" [style.height.px]="windowHeight - 150">
            <div #storyContainer *ngIf="tab =='story' || tab =='news-stories'" class="my-story" style="overflow: auto;">
                <div *ngIf="tab =='story' && userSnapShotList.length == 0" class="danger">
                    <div class="alert alert-danger" role="alert">
                        "Stories will available soon"
                    </div>
                </div>
                <div *ngIf="tab =='story' && userSnapShotList.length == 0" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="grid-container" *ngIf="userSnapShotList.length > 0 && userSnapShotList !== undefined">
                    <div class="grid-item" *ngFor="let snapshot of userSnapShotList; let i = index;">
                        <img (click)="selectImage(i, snapshot.StoryID)" [src]="snapshot.SnapshotFileUrlMini" />
                    </div>fv
                </div>
            </div>
            <div *ngIf="!flipFlag">
                <div *ngIf="tab =='womens-wear' &&  isLoadingStyle == false && showTheBrandMode" class="d-flex my"
                    [ngClass]="this.loadingState   ? 'no-touch' : ''">
                    <div class="file womenswear_scroller ">
                        <div class="file" *ngIf="!isLoading ">
                            <div class="row form "
                                *ngIf="packJsonObject?.length > 0 && packJsonObject[0]?.value != undefined">
                                <div #scrollContainerWHeadGear class="slider-css-head"
                                    (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                    <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                        id="elementwomens-wearheadgear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="head-div">
                                            <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'headgear', object,'HeadwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div #scrollContainerWUpper class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                    <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                        id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
                                        <div class="upper-div">
                                            <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                <div class="spinner-border spn-load text-white" role="status">
                                                </div>
                                            </div>
                                            <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'upperwear',object,'UpperwearID')" />
                                            <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                class="profileInfo   col-sm-2"
                                                style="width:25px; height: 25px !important;"
                                                src="./assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                                <div #scrollContainerWBottom class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                    <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                        id="elementwomens-wearbottomwear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="bottom-div">
                                            <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'bottomwear', object,'BottomwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div #scrollContainerWFootwear class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                    <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                        id="elementwomens-wearfootwear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="foot-div">
                                            <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'footwear', object,'FootwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>

                                <div *ngIf="isLoading" class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class=""></span>
                                    </div>
                                </div>

                            </div>
                        </div> 
                    </div>
                </div>
                <div *ngIf="tab =='mens-wear' &&  isLoadingStyle == false && showTheBrandMode" class="d-flex my"
                    [ngClass]="this.loadingState   ? 'no-touch' : ''">
                    <div class=" file womenswear_scroller ">
                        <div class="file" *ngIf="!isLoading ">
                            <div class="row form d-block " *ngIf="packJsonObject != undefined ">
                                <div #scrollContainerMHeadGear class="slider-css-head"
                                    (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                    <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                        id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="head-div">
                                            <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'headgear', object,'HeadwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>


                                <div #scrollContainerMUpper class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                    <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                        id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                        <div class="upper-div">
                                            <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                <div class="spinner-border spn-load text-white" role="status">
                                                </div>
                                            </div>
                                            <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'upperwear',object,'UpperwearID')" />
                                            <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                class="profileInfo   col-sm-2"
                                                style="width:25px; height:25px !important;"
                                                src="./assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                                <div #scrollContainerMBottom class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                    <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                        id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="bottom-div">
                                            <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'bottomwear', object,'BottomwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div #scrollContainerMFootwear class="slider-css2-upper"
                                    (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                    <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                        id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <div class="foot-div">
                                            <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="open3Dflag(zipList[object],'footwear', object,'FootwearID' )" />
                                        </div>
                                        <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                            class="profileInfo  upperwear_info col-sm-2"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div *ngIf="isLoading" class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class=""></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isloading" class="text-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="flipFlag" [style.height.px]="windowHeight - 170" class="flipFlag-class" id="scrollableDiv"
                #setclothBand>
                <div (click)="flipClose() " class="text-white text-end class-close-btn">X
                </div>
                <div class="set-main-div">
                    <div class="set-img-div" *ngIf="!swichImageFlage">
                        <img *ngIf="garmentClothType != 'bottomwear'" class="img-responsive-flip-class"
                            [src]="source" />
                        <img *ngIf="garmentClothType == 'bottomwear'" class="img-responsive-flip-class-bottom"
                            [src]="source" />
                    </div>
                    <div *ngIf="swichImageFlage">
                        <div class="" id="target" *ngIf="packJsonObject.length > 0 ">
                            <app-twod-image [garmentImg]="this.source" [garmentType]="this.garmentClothType" 
                                [pageType]="style"></app-twod-image>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="isloading">
                            <div class="spinner-border" role="status">
                                <span class=""></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setcloth-band">
                    <div class="mb-2 text-black clothName color-class-band">
                        <div class="d-flex garment-title" data-bs-toggle="modal" data-bs-target="#pageModalLabel"
                            (click)="garmentDetailsEdit(clothDetails)">
                            <div class="text-center d-inline-flex garmen-name-in-band">
                                <div class="">
                                    <span *ngIf="clothDetails?.RealType == '0'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/headgear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '1'" class="brandbtn py-1 "><img style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6; 
                                            margin-right: 4px;" src="./assets/realtype/upperwear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '2'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                            src="./assets/realtype/bottomwear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '3'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/footwear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '4'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/heeledfootwear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '5'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                            src="./assets/realtype/fullbody.png"></span>
                                </div>
                                <h6 class="brand-font class-band-name" style="wrap: break-word;">
                                    {{(clothDetails?.Name).split()}}
                                </h6>
                            </div>
                            <div>
                                <div class="text-center band-3d-icon d-flex col-sm-1">
                                    <h6 class=" text-primary  color-class">
                                        <img *ngIf="!garmentScrollFlag" (click)="switch3DImage()" class="threeD_icon "
                                            src="./assets/realtype/3d-paranoma.png">
                                        <img *ngIf="garmentScrollFlag" (click)="flipClose()" class="threeD_icon "
                                            src="./assets/realtype/paraCloseBlack.png">
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <h6 (click)="selectIcon('popup')" class="text-primary brandbtn m-1 d-block class">
                                <div *ngIf="selectedIcon == 'popup'"
                                    [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card-band'  : 'story-menu-icon'">
                                    <img style="width: 12px;" src="./assets/storystyle/story-white.png">
                                </div>
                                <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                    style="color:black ;margin-top: 0px; width: 10px;"
                                    src="./assets/storystyle/storyblack.png">

                                <h6 *ngIf="selectedIcon == 'popup'" class="selected-name">Story</h6>
                            </h6>
                            <h6 (click)="selectIcon('detail')" *ngIf="clothDetails?.ThumbCenterURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'detail'"
                                    [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank">
                                        <img style="width: 12px;;" src="./assets/details.png"></a>
                                </div>
                                <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'detail'"
                                        [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                        style="color:black ;" src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

                                <h6 *ngIf="selectedIcon == 'detail'" class="selected-name">Details
                                </h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'gallary'"
                                    [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank">
                                        <img style="width: 12px; " src="./assets/gallary.png"></a>
                                </div>
                                <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'gallary'"
                                        [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                        style="color:black;" src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name ">Gallary
                                </h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important ;"
                                        src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'cart'"
                                    [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails?.ThumbRightURL}}" target="_blank">
                                        <img style="width: 12px; " src="./assets/shop.png"></a>
                                </div>
                                <a href="{{clothDetails?.ThumbRightURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'cart'"
                                        [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                        style="color:black; width: 1px;"
                                        src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'cart'" class="selected-name">Shopping
                                </h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="img-detail-story">
                    <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                        <div class="spinner-border is-loading m-3  " role="status">
                            <span class=""></span>
                        </div>
                    </div>
                    <div class="twoDImage d-block mb-2" *ngIf="!isloadingPanel">
                        <div class="" *ngIf="userFileList != undefined">
                            <!-- <app-image-slide [images]="userFileList"></app-image-slide> -->
                            <app-slider-two-d [images]="userFileList" [deleteGarmentUpload]="deleteflag"
                            [clothId]="clothDetails?.id"></app-slider-two-d>
                        </div>
                        <div *ngIf="userFiledetails3d != undefined">
                            <!-- <app-image-slide [images]="userFiledetails3d">
                            </app-image-slide> -->
                            <app-slider-two-d [images]="userFiledetails3d" [deleteGarmentUpload]="deleteflag"
                            [clothId]="clothDetails?.id"></app-slider-two-d>
                        </div>
                        <div class="d-flex justify-content-center ">
                            <span data-bs-toggle="modal" data-bs-target="#upload2D"><img
                                    style="width:30px !important; color:black ;padding-bottom: 3px;"
                                    src="./assets/upload-icon/2D.png"></span>
                            <span data-bs-toggle="modal" data-bs-target="#upload3D"><img
                                    style="width:30px !important; color:black ;padding-bottom: 3px;"
                                    src="./assets/upload-icon/3D.png"></span>
                        </div>
                    </div>
                    <div data-bs-toggle="modal" data-bs-target="#pageModalLabel"
                        (click)="garmentDetailsEdit(clothDetails)"
                        [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-2' : 'collection-details-sku3d details'">
                        <h6 class="color-class "
                            *ngIf="clothDetails?.CollectionName != undefined && clothDetails?.CollectionName != '' ">
                            Collection: {{(clothDetails.CollectionName)}}</h6>
                        <h6 class=" color-class "
                            *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">
                            Collection: None</h6>

                        <h6 class=" color-class"
                            *ngIf="clothDetails?.Details != undefined &&  userFiledetails3d.length != 0">
                            {{(showMore) ? clothDetails .Details : clothDetails .Details | slice:0:120}}
                            <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                            <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                                (click)="showMore=false">Read less</a>
                        </h6>

                        <h6 class="color-class"
                            *ngIf="clothDetails?.Details != undefined  &&  userFiledetails3d.length == 0 ">
                            {{(showMore) ? clothDetails.Details : clothDetails .Details| slice:0:400}}
                            <span *ngIf="!showMore && clothDetails.Details.length < 400">...</span>
                            <a href="javascript:;"
                                *ngIf="!showMore && clothDetails.Details.length > 400  &&  userFiledetails3d.length == 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                                (click)="showMore=false">Read less</a>
                        </h6>
                        <h6 class="color-class"
                            *ngIf="clothDetails?.Details == undefined  &&  userFiledetails3d.length < 0 ">
                            {{(showMore) ? dummyText : dummyText | slice:0:150}} <span *ngIf="!showMore">...</span>
                            <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                                (click)="showMore=false">Read less</a>
                        </h6>
                        <h6 class="color-class"
                            *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length == 0">

                            Your brand's apparel is digitised in 3D
                            <br><br>
                            On a gamified experience, consumers style avatars & share animated avatar
                            stories
                            (linked to ecommerce)
                            <br><br>
                            Defining a new paradigm in immersive storytelling for social media & the
                            metaverse
                        </h6>
                        <h6 class="color-class"
                            *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0  && userFiledetails.length > 0">
                            {{(showMore) ? dummyText : dummyText}} "
                            <a href="javascript:;" *ngIf="!showMore &&  
                    clothDetails .Details == undefined &&  
                    userFiledetails3d.length > 0  && userFiledetails.length > 0 " (click)="showMore=true">Read More</a>
                            <a href="javascript:;"
                                *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFiledetails.length > 0"
                                (click)="showMore=false">Read less</a>
                        </h6>

                        <h6 class="color-class"
                            *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0">
                            {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                                *ngIf="!showMore && clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read
                                less</a>
                        </h6>
                        <h6 class="color-class">SKU: {{clothDetails?.SKU}}</h6>
                    </div>
                    <div #storyContainer class="mb-5  my-storyzz" *ngIf="!isloadingPanel">
                        <div class=" row justify-content-center "
                            *ngIf="userSnapShotListGarment.length >= 0  && userSnapShotListGarment != undefined && userSnapShotListGarment.length >= 0">
                            <!-- <div class="column d-block  "
                                *ngFor="let snapshot of userSnapShotListGarment; let i=index;">
                                <img [ngClass]="{'story_image_selection': i == selectedImageIndex}"
                                    class="columnImg d-block  shadow-1-strong rounded mb-1 "
                                    [src]="snapshot.SnapshotFileUrlMini">
                            </div> -->

                            <div class="grid-container"
                                *ngIf="userSnapShotListGarment.length > 0 && userSnapShotListGarment !== undefined">
                                <div class="grid-item" *ngFor="let snapshot of userSnapShotListGarment; let i = index;">
                                    <img [ngClass]="{'story_image_selection': i === selectedImageIndex}"
                                        [src]="snapshot.SnapshotFileUrlMini" (click)="selectImage(i, snapshot.StoryID)">
                                </div>
                            </div>
                            <div class="col-12 text-center text-white stify-content-center">
                                <h6 *ngIf="this.userSnapShotListGarment.length >= 12 ">
                                    <b>More</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                        <div class="spinner-border is-loading m-3 " role="status">
                            <span class=""></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="col-lg-12 m-1 justify-content-center text-center scroll" *ngIf="clothDetailsList != undefined">
    <div class="container garment-alingn">
        <div class="col-lg-12 d-flex justify-content-center">
            <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                <div class="text-center d-flex  heeadWidth"><img class="profileImg-brand "
                        style="width:35px; height:35px !important;" [src]="getBrandImage(brandProfileDetails.LogoURL)">
                </div>
                <div class="m-0 heeadWidth">
                    <div class="container d-block col-12  ">
                        <h6 class="color-class ">
                            {{brandProfileDetails.BrandName}}
                        </h6>
                        <div class=" col-12 details-for-task " *ngIf="clothDetailsList != undefined">
                            <b>
                                <h6 class="color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">
                                    {{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                                    (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}
                                </h6>
                            </b>
                        </div>
                    </div>
                </div>
                <div class="m-0 heeadWidth text-end">
                    <button type="button" class="btn btn-sm btn-outline-info m-2 follow color-class ">Follow</button>
                </div>
            </div>
        </div>

        <b>
            <h6 data-bs-toggle="modal" data-bs-target="#pageModalLabel" (click)="garmentDetailsEdit(clothDetailsList)"
                class="clothName color-class ">
                <span *ngIf="clothDetailsList.RealType == '0'" class="brandbtn py-1"><img
                        style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/headgear.png"></span>
                <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1"><img
                        style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetailsList.RealType == '2'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
                        src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetailsList.RealType == '3'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetailsList.RealType == '4'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetailsList.RealType == '5'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/fullbody.png"></span>
                {{(clothDetailsList.Name)}}
            </h6>
        </b>
        <div class="details" data-bs-toggle="modal" data-bs-target="#pageModalLabel"
            (click)="garmentDetailsEdit(clothDetailsList)">
            <h6 class=" color-class "
                *ngIf="clothDetailsList.CollectionName != undefined && clothDetailsList.CollectionName != null">
                Collection: {{filterCollectionName(clothDetailsList.CollectionName)}}</h6>
            <h6 class=" color-class "
                *ngIf="clothDetailsList.CollectionName == '' ||  clothDetailsList.CollectionName == null">Collection:
                None</h6>

            <h6 class=" color-class ">Details: {{clothDetailsList.Details}}</h6>
            <h6 class=" color-class ">SKU: {{clothDetailsList.SKU}}</h6>
        </div>

        <div class="container d-flex col-12 mb-4  ">
            <div class=" col-6 details-for-task" *ngIf="clothDetailsList != undefined">
                <b>
                    <h6 class=" color-class ">Cloth Gender :{{clothDetailsList.ClothGender}}</h6>
                    <h6 class=" color-class ">Image Pack : {{clothDetailsList.ImagePack}}</h6>
                    <h6 class=" color-class ">Thumb Size X: {{clothDetailsList.ThumbSizeX}}</h6>
                </b>
                <span data-bs-toggle="modal" data-bs-target="#upload2D"><img
                        style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/upload-icon/2D.png"></span>
            </div>
            <div class=" col-6 details-for-task">
                <b>
                    <h6 class=" color-class "> Real Type:{{clothDetailsList.RealType == '0' ? 'headgear' :
                        (clothDetailsList.RealType ==
                        '1') ? 'upperwear': (clothDetailsList.RealType == '2') ? ' bottomwear ' :
                        (clothDetailsList.RealType == '3') ? 'footwear' : (clothDetailsList.RealType == '4') ?
                        'heeledfootwear ' : (clothDetailsList.RealType == '5') ? 'fullbody ' :'N/a' }}</h6>
                    <h6 class=" color-class "> Prefeb : {{clothDetailsList.Prefeb}}</h6>
                    <h6 class=" color-class "> Thumb Size Y: {{clothDetailsList.ThumbSizeY}}</h6>
                </b>
                <span class="uploadImage" data-bs-toggle="modal" data-bs-target="#upload3D"><img
                        style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/upload-icon/3D.png"></span>
            </div>
        </div>
    </div>
    <div class="imagesDiv">
        <div class="" *ngIf=" userFiledetails.length != 0">
            <app-slider-two-d [images]="userFiledetails" [deleteGarmentUpload]="deleteflag"
                [clothId]="clothDetailsList.id"></app-slider-two-d>
        </div>
        <div class="" *ngIf="userFiledetails.length == 0 ">
            <app-slider-two-d [images]="images"></app-slider-two-d>
        </div>
        <div class="text-center d-flex upload-image-slider-css twoDImage" *ngIf=" userFiledetails3d.length != 0 ">
            <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                <img (click)="openStoreGltfLargView(fileList.fileurl,fileList)" *ngIf="fileList.fileurlMini == undefined"
                    style="height: 110px; width: 150px; float: none;" src="assets/Thumbnail_not_available.png">
                <img (click)="openModel(fileList.fileurl,fileList)"  
                *ngIf="fileList.fileurlMini !== undefined" style="height: 110px; width: 150px; float: none;"
                    [src]="fileList.fileurlMini">
            </div>
        </div>
    </div>
    <div class="p-2 m-1 text-center d-flex justify-content-center">
        <h6 class="m-1 text-primary brandbtn color-class"
            [ngClass]="  clothDetailsList.BrandID == brandId ? 'border-bottom border-dark' : ''"
            (click)="getStoryForGenderCloth(clothDetailsList)"><img style="width:15px !important; color:black"
                src="./assets/storystyle/story-white.png"></h6>
        <h6 (click)="getBrandStyle(clothDetailsList)" class="m-1 text-primary brandbtn color-class"><img
                style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
        </h6>
        <h6 *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img
                    style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
        <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                    style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>

        <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
                    src="./assets/gallary.png"></a></h6>
        <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                    style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>

        <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img
                    style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
        <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                    style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
        </h6>
    </div>




    <div *ngIf="selectedTab != 'user-story'">
        <div class="row mb-5 p-2 justify-content-center" *ngIf="userSnapShotList.length >= 0 ">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded mb-1" (click)="scrollToStory(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
        </div>
    </div>
    <div *ngIf="selectedTab == 'user-story'" class="text-start p-1 mb-5 user-story ">
        <app-user-story [thresholdHeightPagination]="0" [userSnapShotsInput]="userSnapShotList" [garmentId]="clothId"
            [StoryIds]="idSelected" [routLogInFlag]="logInFlag">
        </app-user-story>
    </div>
</div> -->

<div class="modal fade" id="pageModalLabel" tabindex="-1" aria-labelledby="pageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="pageModalLabel">Edit Garment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="m-2">Garment Name</label>
                <input type="text" class="form-control" placeholder="Add Garment Name" (change)="setGarmentName($event)"
                    [value]="clothDetailsList?.Name">
                <label class="m-2">Collection</label>
                <select class="form-control" aria-label="Default select example" (change)="selectCollection($event)">
                    <option
                        [selected]="selectedOption == undefined || (filterdataGarmentCollection?.status == 'I' && selectedOption != undefined )"
                        value=''>None</option>
                    <option *ngFor="let colection of  collectionFilterData" [selected]="selectedOption == colection?.id"
                        [value]="colection?.id">
                        <div>{{colection?.CollectionName}} </div>
                    </option>
                </select>
                <label class="m-2">Details</label>
                <!-- <input type="text" class="form-control" > -->
                <textarea class="form-control" id="exampleFormControlTextarea5" placeholder="Add Details" rows="5"
                    (change)="setGarmentDetails($event)" [value]="clothDetailsList?.Details"></textarea>
                <label class="m-2">SKU</label>
                <input type="text" class="form-control" placeholder="Add SKU" (change)="setGarmentSKU($event)"
                    [value]="clothDetailsList?.SKU">
            </div>
            <label class="m-2"><img style="width:20px !important; " src="./assets/details_black.png"></label>
            <input type="text" class="form-control" placeholder="Add Details Link"
                (change)="setGarmentThumbCenterURL($event)" [value]="clothDetailsList?.ThumbCenterURL">
            <label class="m-2"><img style="width:20px !important" src="./assets/gallary_black.png"></label>
            <!-- <input type="text" class="form-control" > -->
            <input class="form-control" id="exampleFormControlTextarea5" placeholder="Add Gallery Link" rows="5"
                (change)="setGarmentThumbLeftURL($event)" [value]="clothDetailsList?.ThumbLeftURL">
            <label class="m-2"><img style="width:20px !important" src="./assets/shop_black.png"></label>
            <input type="text" class="form-control" placeholder="Add Shop Link"
                (change)="setGarmentThumbRightURL($event)" [value]="clothDetailsList?.ThumbRightURL">
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="updateGarmentDetailsEdit()"
                    data-bs-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="fileView-profile" tabindex="-1" aria-labelledby="fileView-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <img src={{showImages?.fileurl}}>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="upload2D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 (click)="clear()" type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
            </h5>
            <div class="justify-content-center text-center">
                <h5>Upload File</h5>
            </div>
            <div class="justify-content-center text-center">
                <input type="text" [value]="envName" (change)="setEnvName($event)"
                    class="form-control m-2 p-2 input-wid" placeholder="Enter file name" />
                <!-- <div class="text-start text-danger m-1" *ngIf="envName == ''">Plese Enter File Name</div> -->
                <span> <input type="file" [value]="uploadFileName" (change)="start2DFileUpload($event)"
                        class="form-control m-2 p-2 input-wid">
                </span>
            </div>
            <div class="p-2" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="upload3D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" (click)="clear()" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
            </h5>
            <div class="justify-content-center text-center">
                <h5>Upload File</h5>
            </div>
            <div class="justify-content-center text-center">
                <input [value]="envName" (change)="setEnvName($event)" class="form-control m-2 p-2 input-wid"
                    type="text" placeholder="Enter file name" />
                <!-- <div class="text-start text-danger m-1" *ngIf="envName == ''">Plese Enter File Name</div> -->
                <input [value]="uploadFileName" type="file" (change)="start3DFileUpload($event)"
                    class="form-control m-2 p-2 input-wid">
            </div>
            <div class="p-2" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div>