<div class="d-flex justify-content-center m-2 " *ngIf="showDisplayText != '' || isloadingForChange">
    <div class="spinner-border text-white top-scroll p-2 loading " role="status">
    </div>
</div>
<div class="evnLoading text-white " *ngIf="showDisplayText != ''" [ngClass]="FunctionFlag == 'updateWindowSize' ? 'evnLoading-store-front' : ''" >
    {{showDisplayText}}
</div>
<div class="d-div" *ngIf="showDisplayText != '' || isloadingForChange"></div>
<div #threeDModelContainerMale></div>


<div class="postcard-img-size" *ngIf="isloadingForChange && (this.BlobUrl && this.BlobUrl != undefined)" >
    <img class="img-size" [src]="this.BlobUrl ? sanitizer.bypassSecurityTrustUrl(this.BlobUrl) : ''" alt="">
</div>