<div>
    <app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>
    <div (scroll)="scrollHandler()" #userFeeds class="roksan" [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}" >
        <div class="col-lg-12  page-scroll sticky-band" *ngIf="pageScroll > 30">
            <span class="d-flex justify-content-center ">
                <div class=" col-lg-12 band justify-content-center">
                    <div class="static-band " style="margin-left: 0px  !important;">
                        <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                            <img *ngIf="userProfileData?.ProfilePicture != undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                            <img *ngIf="userProfileData?.ProfilePicture == undefined"
                                class="img-thumbnail rounded-circle" style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " src={{userProfileData?.ProfilePicture}}>
                        </div>
                        <div class="w-100 col-12 d-flex">
                            <div class="col-9 text-start">
                                <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                    (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                                <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black"
                                    (click)="getSnapShotListByUserID()">
                                    {{userProfileData?.DisplayName}}
                                </h5>
                            </div>
                            <div class="col-3 "><button type="button" style="font-size: 10px;"
                                    class="btn btn-outline-info">Follow</button>
                            </div>
                        </div>
                        <div class="text-start" style="height: 25px; width: 100%;">
                            <h6 *ngIf="this.avatarID != undefined && this.userId != undefined"
                                class="avatr  text-start ">
                                🧍🏽{{avatarID}}
                            </h6>
                        </div>

                        <div>
                            <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                <div class="brandbtn">
                                    <h6 class="m-1" (click)="selectSummeryView('Story')"
                                        [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>

                                            <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                src="  ./assets/storystyle/story-white.png">
                                            <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                src=" ./assets/storystyle/storyblack.png">


                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Brands')">
                                    <h6 class="m-1"
                                        [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                src="./assets/brand_white.png">
                                            <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                src="./assets/brand_black.png">

                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                </div>
                                <div class="brandbtn" (click)="selectTab('Style')">
                                    <h6 class="m-1 "
                                        [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                        <a>
                                            <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_white.png">
                                            <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                src="./assets/t_shirt_black.png">
                                        </a>
                                    </h6>
                                    <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div *ngIf="filterSnap" class="justify-content-center">
            <div class="col-lg-12 band">
                <div *ngIf="snaploader2D" class="text-center">
                    <div class="spinner-border m-2 p-2" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="static-band justify-content-center"
                    *ngIf=" pageScroll == undefined ||  pageScroll < 30    ">
                    <div class="d-flex m-1 profile-popup text-white " data-bs-dismiss="modal">
                        <img *ngIf="userProfileData?.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.ProfilePicture}}>
                        <img *ngIf="userProfileData?.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                            style="
                          width: 60px !important;
                          height: 60px !important;
                          margin-top: -53px;
                      " src={{userProfileData?.photoURL}}>
                    </div>
                    <div class="w-100 col-12 d-flex">
                        <div class="col-9 text-start">
                            <h5 *ngIf="userProfileData?.DisplayName == undefined" class="m-1 text-start text-black"
                                (click)="getSnapShotListByUserID()">{{userData?.profileName}}</h5>
                            <h5 *ngIf="userProfileData?.DisplayName != undefined" class="m-1 text-black"
                                (click)="getSnapShotListByUserID()">
                                {{userProfileData?.DisplayName}}
                            </h5>
                        </div>
                        <div class="col-3 "><button type="button" style="font-size: 10px;"
                                class="btn btn-outline-info">Follow</button>
                        </div>
                    </div>
                    <div class="text-start" style="height: 25px; width: 100%;">
                        <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">
                            🧍🏽{{avatarID}}
                        </h6>
                    </div>


                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                        <div class="brandbtn">
                            <h6 class="m-1   " (click)="selectSummeryView('Story')"
                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                        src="  ./assets/storystyle/story-white.png">
                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                        src=" ./assets/storystyle/storyblack.png">

                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                        </div>
                        <div class="brandbtn" (click)="selectTab('Brands')">
                            <h6 class="m-1 "
                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                        src="./assets/brand_white.png">
                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                        src="./assets/brand_black.png">


                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                        </div>
                        <div class="brandbtn">
                            <h6 class="m-1  " (click)="selectTab('Style')"
                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                <a>
                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_white.png">
                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_black.png">
                                </a>
                            </h6>
                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class ">Style</h6>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center story-center d-flex">
                    <h6 class="m-2 text-primary  color-class">
                        <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                            <div *ngIf="userFiledetails.length == 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="images"></app-slider-two-d>
                            </div>
                            <div *ngIf="userFiledetails.length != 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="userFiledetails" [UserId]="userId"></app-slider-two-d>
                            </div>
                        </div>
                    </h6>
                </div>
            </div>
            <div *ngIf="snaploader" class="text-center">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>

        <!-- 
    <div class="d-flex justify-content-center">
        <select class="form-select drp-style" (change)="selectAvatarId($event)">
            <option value="">Select Avatar</option>
            <option [value]="avatar.AvatarID" *ngFor="let avatar of newAvatarList">
                {{avatar.AvatarName != undefined || avatar.AvatarName != null ? avatar.AvatarName :avatar.AvatarID}}
            </option>
        </select>
    </div> -->

        <!-- <div class="d-flex justify-content-center">
        <select class="form-select drp-style" (change)="selectAvatarId($event)">
            <option value="">Select Avatar</option>
            <option *ngFor="let avatar of newAvatarList" [value]="avatar.AvatarID">
                <img *ngIf=" avatar.AvatarPreview_PortraitUrl != undefined" class="avatar-image" src="{{ avatar.AvatarPreview_PortraitUrl }}" alt="Avatar Image">
                {{avatar.AvatarName != undefined || avatar.AvatarName != null ? avatar.AvatarName :avatar.AvatarID}} 
            </option>
        </select>
    </div> -->


    <div class="d-flex justify-content-center m-1">
        <div class="dropdown custom-dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                style="background-color: white; color: black; width: 220px;" data-bs-toggle="dropdown"
                aria-expanded="false">
                <span *ngIf="!avatarObject">Select Avatar</span>
                <span>
                    <img *ngIf="avatarObject?.AvatarPreviewPortraitUrl" class="avatar-image"
                        src="{{ avatarObject.AvatarPreviewPortraitUrl }}" alt="Avatar Image">
                    {{ avatarObject?.AvatarName != undefined || avatarObject?.AvatarName != null ? avatarObject?.AvatarName
                    : avatarObject?.AvatarID}}
                </span>
            </button>
            <ul class="dropdown-menu scroll-drop-item" aria-labelledby="dropdownMenuButton">
                <li *ngFor="let avatar of newAvatarList" class="dropdown-item " (click)="selectAvatarId(avatar)">
                    <img *ngIf="avatar.AvatarPreviewPortraitUrl" class="avatar-image"
                        src="{{ avatar.AvatarPreviewPortraitUrl }}" alt="Avatar Image">
                    {{ avatar.AvatarName != undefined || avatar.AvatarName != null ? avatar.AvatarName : avatar.AvatarID }}
                    {{ avatar.AvatarName == undefined || avatar.AvatarName == null ? " " : - avatar.AvatarID }}
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-start">
            <div *ngIf="this.selectedavatarId != undefined">
                <button class="btn btn-secondary" style="margin-left: 2px;" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false" (click)="navigateToAvatarPage()">
                    Go
                </button>
            </div>
        </div>
    </div>
    
        <!-- Ensure Bootstrap 5 JavaScript is loaded after this code -->





        <div *ngIf="selectedTab  == 'user-story' && showDripPage" class="story-slass">
            <!-- <app-user-story [userSnapShotsInput]="filterSnap" [UserId]="userId" [AvatrId]="avatarID"
                [StoryIds]="selectedStoryId" [PageCount]="pagecount" [pageNumber]="pageNumber"
                (outPageNumber)="setPageNumber($event)" [brandPageFlag]="brandPageFlag" [rootFlag]="rootFlag"
                [routLogInFlag]="routLogInFlag">
            </app-user-story> -->

         
            <div class="drips-container" >
                <app-drips (emmitStoryIndex)="handleStoryCall($event)" 
                (visibleIndex)="handlePreviousPage($event)" 
                 [mainMenuFlag]="mainMenuFlag" 
                 [showpanel]="showpanel"
                 [userSnapShotsInput]="filterSnap"
                 [StoryIds]="selectedStoryId"
                 [UserId]="userId" 
                 [AvatrId]="avatarID" 
                 [PageCount]="pagecount"
                 [nextPage]="nextPage" 
                 [pageNumber]="pageNumber"
                 
                 >
                </app-drips>
            </div>

        </div>

        <div *ngIf="selectedTab  == 'story'" class="row justify-content-center mb-5  ">

            <div class="col-12 div-center">
                <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
                <div class="row w-100">
                    <div class="col-4 p-0" *ngFor="let snapshot of filterSnap; let i=index;">
                        <div *ngIf="snapshot.SnapshotFileUrl != ''" class="d-flex align-items-center justify-content-center m-1">
                            <img  class="w-100 shadow-1-strong rounded m-1" (click)="scrollToStory(snapshot)"
                                [src]="snapshot.SnapshotFileUrl">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="filterSnap.length >= 12">
                <div  class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID != undefined  }">
                    <h6 (click)="nextButton(counter)"><b>More</b></h6>
                </div>
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID == undefined  }">
                    <h6 (click)="nextButtonForAvatar(counter)"><b>More</b></h6>
                </div>
            </div>
        </div>
    </div>
</div>